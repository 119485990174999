import {useLocale} from "../../../services/hooks/useLocale";
import {useCallback} from "react";

export const LanguageButtons = () => {
    const {currentLocale, setCurrentLocale, locales} = useLocale()
    const handleClick = useCallback((locale: string) => {
        setCurrentLocale(locale)
    }, [])
    return <div>
        {locales?.map(locale => {
            return locale != currentLocale &&
				<button onClick={() => handleClick(locale)} key={'button-' + locale}
						className="animate-page-appear">
					<img className="w-10 h-auto drop-shadow" src={require("../../../assets/img/" + locale + ".svg")}/>
				</button>
        })}
    </div>
}