import {FieldModel} from "./FieldModel";
import {SimpleSelectDataType} from "../../../api/types/FormDataTypes";

export class SimpleSelectModel extends FieldModel {
    isMultiple: boolean
    options: { label: string; value: string } []

    constructor(data: SimpleSelectDataType) {
        super(data)
        this.options = data.options
        this.isMultiple = data.isMultiple
    }
}