import { ReactNode } from "react"

type Types = {
    children: ReactNode
}
export const ModalInnerBox = ({ children }: Types) => {
    return <div
        className="w-max p-8 md:p-12 bg-white shadow-xl rounded-xl max-w-[80vw] flex flex-col gap-4 justify-center items-center">
        {children}
    </div>
}