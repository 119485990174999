import {useFormContext} from "react-hook-form";
import {InputModel} from "../../../../services/models/forms/fields/InputModel";

export const Input = (field: InputModel) => {
    const {type, label, name, isRequired, invalidMessage} = field
    const {register, watch, formState: {errors}} = useFormContext();
    const value = watch(name, '')
    return (
        <div className="border-b-2 border-blue relative">
            <input id={name}
                   type={type}
                   {...register(name, {required: isRequired})}
                   className="peer appearance-none bg-transparent border-none w-full text-gray-700 mr-3 p-2 leading-tight focus:outline-none"
            />
            <label
                className={"absolute peer-focus:-top-1/2 peer-focus:text-left peer-focus:text-xs left-0 transition-all duration-300 ease-in-out" +
                (value === undefined || !value?.length ? ' top-0 text-md' : ' -top-1/2 text-xs')}
                htmlFor={name}>{label}</label>
            {!!errors[name] && <p className='text-orange'>{invalidMessage}</p>}
        </div>
    );
};