import {QuoteDataType} from "../../api/types/PageDataTypes";

export class QuoteModel {
    id: number
    author: string
    text: string

    constructor(data: QuoteDataType) {
        this.id = data.id
        this.author = data.attributes.author
        this.text = data.attributes.text
    }
}