//libraries
import {createContext, ReactNode, useContext} from "react";
import {useGlobal} from "../hooks/api/useGlobal";
import {LinkModel} from "../models/pages/items/LinkModel";

//services
//components

type Props = {
    children?: ReactNode,
};

type Types = {
    pairs?: { [x: string]: string; }
    contactLinks?: LinkModel[]
}

const GlobalContext = createContext<Types>({});

export const GlobalProvider = ({children}: Props) => {
    const {pairs, contactLinks} = useGlobal()
    return (
        <GlobalContext.Provider
            value={{pairs: pairs, contactLinks: contactLinks}}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export const useGlobalContext = () => useContext(GlobalContext);