import {LinkModel} from "./pages/items/LinkModel";
import {ImageDataType, TechnologyDataType} from "../api/types/EntitiesDataTypes";
import {CollaboratorDataType, LinkDataType, PairDataType} from "../api/types/PageDataTypes";
import {TechnologyModel} from "./entities/TechnologyModel";
import {CollaboratorModel} from "./entities/CollaboratorModel";
import {ImageModel} from "./entities/ImageModel";

export class Serializer {
    static serializeLanguages(data: { attributes: { name: string; reactIcon: string } }[]) {
        const temp: { name: string; icon: string; }[] = []
        data?.forEach(languageData => {
            temp.push({name: languageData.attributes.name, icon: languageData.attributes.reactIcon})
        })
        return temp
    }

    static serializeImages(data: ImageDataType[]) {
        const temp: ImageModel[] = []
        data?.forEach(imageData => {
            temp.push(new ImageModel(imageData))
        })
        return temp
    }

    static serializePairs(data: PairDataType[]) {
        const temp: { [x: string]: string; } = {}
        data.forEach(pairData => {
            temp[pairData.key] = pairData.value
        })
        return temp
    }

    static serializeContactLinks(data: PairDataType[]) {
        const temp: LinkModel[] = []
        data.forEach(pairData => {
            const arr = pairData.key.split(' ')
            const link = new LinkModel({name: arr[1], icon: arr[0], href: pairData.value})
            temp.push(link)
        })
        return temp
    }

    static serializeLinks(data: LinkDataType[]) {
        const temp: LinkModel[] = []
        data?.forEach(linkData => {
            temp.push(new LinkModel(linkData))
        })
        return temp
    }

    static serializeServices(data: { attributes: { name: string } }[]) {
        const temp: string[] = []
        data?.forEach(serviceData => {
            temp.push(serviceData.attributes.name)
        })
        return temp
    }

    static serializeLanguagesFromTechnologies(technologies: TechnologyModel[]) {
        let temp: { name: string; icon: string }[] = []
        technologies?.forEach(techno => {
            if (techno.languages) temp = [...temp, ...techno.languages]
        })
        return temp
    }

    static serializeCollaborators(data: CollaboratorDataType[]) {
        const temp: CollaboratorModel[] = []
        data?.forEach(collaboratorData => {
            temp.push(new CollaboratorModel(collaboratorData))
        })
        return temp
    }

    static serializeTechnologies(data: TechnologyDataType[]) {
        const temp: TechnologyModel[] = []
        data?.forEach(technoData => {
            temp.push(new TechnologyModel(technoData))
        })
        return temp
    }
}