import { useEffect, useState } from "react";

import React from 'react'

type Types = {
    numberOfItems: number
    index?: number
}

export const useSlider = ({ numberOfItems, index = 0 }: Types) => {
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(index)
    const [hasNext, setHasNext] = useState(true)
    const [hasPrevious, setHasPrevious] = useState(false)

    useEffect(() => {
        currentIndex === 0 ? setHasPrevious(false) : setHasPrevious(true)
        currentIndex >= (numberOfItems) ? setHasNext(false) : setHasNext(true)
    }, [currentIndex])


    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchStart(e.targetTouches[0].clientX);
    }

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const handleTouchEnd = () => {
        if (touchStart && touchEnd) {
            hasNext && ((touchStart - touchEnd) > 150) && slideToNext()
            hasPrevious && ((touchStart - touchEnd) < -150) && slideToPrevious()
        }
        resetTouchMeasures()
    }

    const resetTouchMeasures = () => {
        setTouchStart(0)
        setTouchEnd(0)
    }

    const slideToNext = () => {
        if (hasNext) {
            setCurrentIndex(currentIndex + 1)
        }
    }

    const slideToPrevious = () => {
        if (hasPrevious) {
            setCurrentIndex(currentIndex - 1)
        }
    }
    return { currentIndex, hasPrevious, hasNext, handleTouchEnd, handleTouchMove, handleTouchStart, slideToNext, slideToPrevious }
}