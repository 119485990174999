import {H3} from "../headings/H3";
import {useGlobalContext} from "../../../services/providers/GlobalProvider";

export const FormError = () => {
    const {pairs} = useGlobalContext()
    return (
        <div className="card">
            <H3>{pairs?.form_errorTitle}</H3>
            <p>{pairs?.form_errorText}</p>
        </div>
    )
}