import {ReactNode} from "react";
import {Link, useMatch, useResolvedPath} from "react-router-dom";

type types = {
    children: ReactNode,
    to: string
}
const symboles = {
    left: '❮',
    right: '/❯'
}

export const DesktopNavButton = ({children, to}: types) => {
    const resolve = useResolvedPath(to);
    const match = useMatch({path: resolve.pathname});

    return (
        <Link to={to}
              className={'transition-all flex justify-center items-center gap-1'}
        >
            <p className={'rounded-md pt-2 pb-3 px-4 ' + (match ? 'bg-gray-50 shadow-inner' : '') + ' '}>
                <span
                    className={(match ? 'opacity-1' : 'opacity-0') + ' text-blue '}>{symboles.left} </span>
                {children}
                <span
                    className={(match ? 'opacity-1' : 'opacity-0') + ' text-blue '}> {symboles.right}</span>
            </p>

        </Link>
    );
};