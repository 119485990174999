import {FieldModel} from "./FieldModel";
import {RadioListDataType} from "../../../api/types/FormDataTypes";

export class RadioListModel extends FieldModel {
    buttons: {
        label: string
        value: string
    }[]

    constructor(data: RadioListDataType) {
        super(data)
        this.buttons = data.buttons
    }
}