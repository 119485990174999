const API_URL = process.env.REACT_APP_BASE_API_URL || ''
const LOCALES_URL = process.env.REACT_APP_LOCALES_URL || ''
const CONTACT_URL = process.env.REACT_APP_CONTACT_URL || ''

const getGridItemsQuery = (query: string, locale: string) => {
    return '{' + query
        + '(locale:"' + locale + '")'
        + '{data {attributes {links { name icon href } name snippet slug logo { data {attributes { url } } } cover { data { attributes { url } } } } } }'
        + '}'
}

const getIndexItemsQuery = (query: string, queryContent: string, locale: string) => {
    return '{' + query
        + '(locale:"' + locale + '")'
        + queryContent
        + '}'
}

export const fetchGridItems = async (type: string, locale: string) => {
    const data = await fetch(API_URL, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({query: getGridItemsQuery(type, locale)})
    })
    const json = await data.json()
    return json
}

export const fetchIndexItems = async (type: string, queryContent: string, locale: string) => {
    const data = await fetch(API_URL, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({query: getIndexItemsQuery(type, queryContent, locale)})
    })
    const json = await data.json()
    return json
}

export const fetchLocales = async () => {
    const data = await fetch(LOCALES_URL)
    const json = await data.json()
    return json
}

export const sendContactFormData = async (data: Record<string, string>[]) => {
    const res = await fetch(CONTACT_URL, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data)
    })
    const json = await res.json()
    return json
}