import {StepConditionDataType} from "../../api/types/FormDataTypes";

export class StepConditionModel {
    inputName: string
    inputValue: string
    formId: number

    constructor(data: StepConditionDataType) {
        this.inputName = data.inputName
        this.inputValue = data.inputValue
        this.formId = data.form.data.id
    }
}