import {GridItemDataType} from "../../../api/types/PageDataTypes";
import {LinkModel} from "./LinkModel";
import {Serializer} from "../../Serializer";
import {ImageModel} from "../../entities/ImageModel";

export class GridItemModel {
    cover?: ImageModel
    logo?: ImageModel
    name: string
    snippet: string
    links: LinkModel[]
    slug: string

    constructor(data: GridItemDataType) {
        this.slug = data.attributes.slug
        this.cover = data.attributes?.cover?.data && new ImageModel(data.attributes.cover.data)
        this.logo = data.attributes?.logo?.data && new ImageModel(data.attributes.logo.data)
        this.name = data.attributes.name
        this.snippet = data.attributes.snippet
        this.links = Serializer.serializeLinks(data.attributes.links)
    }
}