import {useEffect, useState} from "react";
import {IoMdArrowRoundUp} from "react-icons/io";

export const BackToTopButton = () => {
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 400 ? setShowButton(true) : setShowButton(false);
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return showButton ? <button onClick={goToTop}
                                className="z-20 flex flex-col items-center text-white bg-blue animate-page-appear bottom-8 right-8 fixed p-3 shadow-xl rounded-full">
        <IoMdArrowRoundUp className="text-3xl"/>
    </button> : <></>
}