import {useFormContext} from "react-hook-form";
import {TextareaModel} from "../../../../services/models/forms/fields/TextareaModel";

export const Textarea = (field: TextareaModel) => {
    const {label, name, isRequired, invalidMessage} = field
    const {register, formState: {errors}} = useFormContext();
    return (
        <div className="flex flex-col gap-4">
            <label htmlFor={name}>{label}</label>
            <textarea
                {...register(name, {required: isRequired})}
                name={name}
                id={name}
            />
            {!!errors[name] && <p className='text-orange'>{invalidMessage}</p>}
        </div>
    );
};