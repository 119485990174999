import React from "react";

const LogoPlaceholderComponent = () => {
    return <svg className="w-10 h-10" version="1.1" viewBox="0 0 69.283 53.148" xmlns="http://www.w3.org/2000/svg">
        <circle cx="53.534" cy="15.749" r="15.749" className="fill-gray-50"/>
        <circle cx="28.27" cy="28.555" r="23.622" className="fill-white"/>
        <path
            d="m67.527 22.974c-4.0452 1.7214-12.448 3.1014-16.226 0.33459 0.60564 2.709 0.75546 5.7326 0.3962 8.2674 8.6569 0.52671 13.75-4.4962 15.83-8.602z"
            className="fill-gray-300"/>
        <path
            d="m10.99 12.427c1.3533 7.6769 8.5698 14.888 16.634 15.956 0 0 4.5203-1.2643 7.4551-3.9631 2.9347-2.6988 5.5493-7.9225 1.4239-8.2459-3.5309 1.6691-7.0139 2.6161-10.899 2.4585s-9.442-1.3414-14.613-6.2054z"
            className="fill-gray-300"/>
        <path
            d="m49.157 9.468c-1.4314-0.0223-2.9484 0.1656-4.2922 0.59272-2.6877 0.85425-5.464 4.5301-8.5434 6.1556-6.9156 3.0803-7.4366 6.3039-8.5553 11.834-1.5839 5.3187-13.329 20.05-27.766 20.497 35.077 17.19 40.894-19.016 42.96-23.35 2.0664-4.334 7.3991-3.5703 10.444-7.5073 2.3545-3.0443 4.1438-4.6749 7.8796-5.6658-0.27455-0.52424-1.1059-0.88429-2.2428-0.97772-1.6137-0.13261-4.0981-0.0836-6.104-0.91053-1.0029-0.41348-2.3488-0.64591-3.7801-0.66818z"
            className="fill-gray-300"/>
    </svg>
}

export const LogoPlaceholder = React.memo(LogoPlaceholderComponent)