import {ImageDataType} from "../../api/types/EntitiesDataTypes";

export class ImageModel {
    basic: string
    medium: string
    small: string
    thumbnail: string

    constructor(data: ImageDataType) {
        this.basic = data?.attributes.url
        this.small = data?.attributes?.formats?.small?.url
        this.medium = data?.attributes?.formats?.medium?.url
        this.thumbnail = data?.attributes?.formats?.thumbnail?.url

    }
}