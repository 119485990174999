import {ComplexFormModel} from "../../../services/models/forms/ComplexFormModel";
import {useComplexForm} from "../../../services/hooks/useComplexForm";
import {FormLoader} from "./FormLoader";
import {FormError} from "./FormError";
import {FormSuccess} from "./FormSuccess";
import {FormIntro} from "./FormIntro";
import {FormStep} from "./FormStep";

type Types = {
    form: ComplexFormModel
}

export const ComplexForm = ({form}: Types) => {
    const firstForm = form.forms.find(step => step.id === form.firstFormId)
    const {
        success, error, isSending,
        onStepSubmit,
        onFinalSubmit,
        handlePrevious,
        currentStep,
        displayIntro,
        handleDisplayForm,
        formData
    } = useComplexForm(form, firstForm)

    if (error) return <FormError/>

    if (success) return <FormSuccess/>

    if (isSending) return <FormLoader/>

    if (displayIntro) return <FormIntro
        text={form.intro}
        heading={form.heading}
        subHeading={form.subHeading}
        handleDisplayForm={handleDisplayForm}
    />
    else return <FormStep
        onSubmit={currentStep?.stepConditions?.length ? onStepSubmit : onFinalSubmit}
        formData={formData}
        step={currentStep || firstForm}
        handlePrevious={handlePrevious}
        hasNext={currentStep?.stepConditions ? (currentStep.stepConditions?.length > 0) : false}
    />
}