import React from "react";
import {LogoPlaceholder} from "./LogoPlaceHolder";
import {Placeholder} from "./Placeholder";

const PlaceholderScreenComponent = () => {
    return <div
        className="w-[80vw] h-56 md:h-64 md:w-96 overflow-hidden rounded-md shadow-2xl border-8 border-b-gray-500 border-t-gray-400 border-r-gray-500 border-l-gray-400 text-xs text-gray-500 bg-white">
        <div
            className="flex py-1 px-4 gap-4 justify-between items-center w-full bg-gray-200 shadow-lg">
            <LogoPlaceholder/>
            <p>Accueil</p>
            <p>À propos</p>
            <p>Contact</p>
        </div>
        <div className="flex justify-between items-center p-8 h-52 gap-4">
            <div className="flex flex-col gap-4 items-center justify-center">
                <p className="text-center font-display text-md text-gray-400">
                    Un site dont je ne peux pas montrer de capture d'écran
                </p>
                <div className="rounded-md py-2 px-3 shadow-md bg-gray-300 text-white">
                    Bouton bidon
                </div>
            </div>
            <Placeholder/>
        </div>
    </div>
}

export const PlaceholderScreen = React.memo(PlaceholderScreenComponent)