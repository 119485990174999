import {gql} from "@apollo/client";

export const GET_PROJECT_BY_SLUG = gql`
    query getProjectBySlug($slug:String!, $locale:I18NLocaleCode!) {
        projects(locale:$locale, filters:{slug: {eq:$slug}}) {
            data {
              attributes {
                name
                slug
                startDate
                currentReleaseDate
                snippet
                extendedDescription
                services {data {attributes {name}}}
                collaborators {
                  data {
                    attributes {
                      name
                      links {href icon name}
                    }
                  }
                }
                images(pagination: {limit:20}) {data {attributes {url}}}
                languages {data {attributes {name reactIcon}}}
                technologies {
                  data {
                    attributes {
                      name
                      description
                      reactIcon
                      links {href icon name}
                      languages {data {attributes {name reactIcon}}}
                    }
                  }
                }
              }
            }
          }
        }
`