import {GET_PAGE_BY_PATH} from "../../api/queries/PagesQueries";
import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {PageModel} from "../../models/pages/PageModel";

export const usePage = (path: string) => {
    const {data} = useQuery(GET_PAGE_BY_PATH, {
        variables: {path: path}
    })

    const [content, setContent] = useState<PageModel>()

    useEffect(() => {
        if (data?.pages?.data) {
            setContent(new PageModel(data.pages.data[0]))
        }
    }, [data])

    return {content}
}