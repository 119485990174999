import {H1} from "../components/reused/headings/H1";
import {CallToActionModel} from "../services/models/pages/sections/CallToActionModel";
import {CallToActionSection} from "../components/reused/sections/CallToActionSection";
import {TextSectionModel} from "../services/models/pages/sections/TextSectionModel";
import {TextSection} from "../components/reused/sections/TextSection";
import {H3} from "../components/reused/headings/H3";
import {SectionModel} from "../services/models/pages/sections/SectionModel";
import {Summary} from "../components/reused/items/Summary";
import {useLocation} from "react-router-dom";
import {usePage} from "../services/hooks/api/usePage";

const renderArticleSection = (section: SectionModel) => {
    return section instanceof CallToActionModel && <CallToActionSection key={section.name} section={section}/> ||
        section instanceof TextSectionModel &&
		<TextSection key={section.name} section={section} hasCard={false}/>
}

export const ArticlePage = () => {
    const location = useLocation()
    const {content} = usePage(location.pathname)
    return <div className="bg-white page">
        {content?.title && <H1>{content.title}</H1>}
        {content?.intro && <H3>{content.intro}</H3>}
        {content?.sections &&
        content?.sections.length > 1 && <>
			<Summary sections={content?.sections} title={''}/>
            {content?.sections?.map((section) => {
                return renderArticleSection(section)
            })}
		</>}

        {}
    </div>
}