import {FieldModel} from "./FieldModel";
import {InputDataType} from "../../../api/types/FormDataTypes";

export class InputModel extends FieldModel {
    type: string

    constructor(data: InputDataType) {
        super(data)
        this.type = data.type
    }
}