import React from "react";

const AnimatedLogoSmallComponent = () => {
    return <svg className="group" version="1.1" viewBox="0 0 81.543 66.908" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="linearGradient1016" x1="43.994" x2="13.496" y1="48.466" y2="11.401"
                            gradientTransform="translate(5.7599 4.5263)" gradientUnits="userSpaceOnUse">
                <stop stopColor="#cfe5ed" offset="0"/>
                <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient5536" x1="42.832" x2="77.701" y1="-15.203" y2="16.971"
                            gradientTransform="matrix(.6667 0 0 .6667 20.057 19.981)" gradientUnits="userSpaceOnUse">
                <stop stopColor="#ffe680" offset="0"/>
                <stop stopColor="#fbb13c" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient9529" x1="28.621" x2="42.661" y1="24.235" y2="33.717"
                            gradientTransform="translate(5.7599 6)" gradientUnits="userSpaceOnUse">
                <stop stopColor="#333232" offset="0"/>
                <stop offset="1"/>
            </linearGradient>
            <filter id="filter1983" x="-.19049" y="-.19049" width="1.3969" height="1.4445"
                    colorInterpolationFilters="sRGB">
                <feFlood floodColor="rgb(0,0,0)" floodOpacity=".14902" result="flood"/>
                <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1"/>
                <feGaussianBlur in="composite1" result="blur" stdDeviation="2.5"/>
                <feOffset dx="0.5" dy="2" result="offset"/>
                <feComposite in="SourceGraphic" in2="offset" result="composite2"/>
            </filter>
            <filter id="filter2103" x="-.12192" y="-.12192" width="1.2544" height="1.2862"
                    colorInterpolationFilters="sRGB">
                <feFlood floodColor="rgb(0,0,0)" floodOpacity=".10196" result="flood"/>
                <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1"/>
                <feGaussianBlur in="composite1" result="blur" stdDeviation="2.4"/>
                <feOffset dx="0.5" dy="2" result="offset"/>
                <feComposite in="SourceGraphic" in2="offset" result="composite2"/>
            </filter>
            <filter id="filter2133" x="-.093989" y="-.13186" width="1.1961" height="1.3095"
                    colorInterpolationFilters="sRGB">
                <feFlood floodColor="rgb(0,0,0)" floodOpacity=".10196" result="flood"/>
                <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1"/>
                <feGaussianBlur in="composite1" result="blur" stdDeviation="2.4"/>
                <feOffset dx="0.5" dy="2" result="offset"/>
                <feComposite in="SourceGraphic" in2="offset" result="composite2"/>
            </filter>
        </defs>
        <circle id="sun" cx="59.294" cy="21.749" r="15.749" fill="url(#linearGradient5536)" filter="url(#filter1983)"/>
        <path id="right-wing"
              d="m73.287 28.974c-4.0452 1.7214-12.448 3.1014-16.226 0.33459 0.60564 2.709 0.75546 5.7326 0.3962 8.2674 8.6569 0.52671 13.75-4.4962 15.83-8.602z"
              className="origin-top will-change-transform inline-block group-hover:animate-fly-right"/>
        <circle id="belly" cx="34.03" cy="34.555" r="23.622" fill="url(#linearGradient1016)" filter="url(#filter2103)"/>
        <path id="left-wing"
              d="m16.75 18.427c1.3533 7.6769 8.5698 14.888 16.634 15.956 0 0 4.5203-1.2643 7.4551-3.9631 2.9347-2.6988 5.5493-7.9225 1.4239-8.2459-3.5309 1.6691-7.0139 2.6161-10.899 2.4585s-9.442-1.3414-14.613-6.2054z"
              fill="#333232" className="origin-top will-change-transform inline-block group-hover:animate-fly-left"/>
        <path id="body-up"
              d="m54.917 15.468c-1.4314-0.0223-2.9484 0.1656-4.2922 0.59272-2.6877 0.85425-5.464 4.5301-8.5434 6.1556-6.9156 3.0803-7.4366 6.3039-8.5553 11.834-1.5839 5.3187-13.329 20.05-27.766 20.497 35.077 17.19 40.894-19.016 42.96-23.35 2.0664-4.334 7.3991-3.5703 10.444-7.5073 2.3545-3.0443 4.1438-4.6749 7.8796-5.6658-0.27455-0.52424-1.1059-0.88429-2.2428-0.97772-1.6137-0.13261-4.0981-0.0836-6.104-0.91053-1.0029-0.41348-2.3488-0.64591-3.7801-0.66818z"
              fill="url(#linearGradient9529)" filter="url(#filter2133)"/>
    </svg>
}

export const AnimatedLogoSmall = React.memo(AnimatedLogoSmallComponent)