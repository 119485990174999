import {LinkDataType} from "../../../api/types/PageDataTypes";

export class LinkModel {
    name: string
    href: string
    icon: string

    constructor(data: LinkDataType) {
        this.name = data.name
        this.href = data.href
        this.icon = data.icon
    }
}