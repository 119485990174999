import {RouteDataType} from "../api/types/PageDataTypes";

export class RouteModel {
    path: string
    name: string
    isInMenu: boolean
    icon: string
    type: string
    locale: string
    number: number

    constructor(data: RouteDataType) {
        this.path = data.attributes.path
        this.name = data.attributes.name
        this.icon = data.attributes.icon
        this.isInMenu = data.attributes.isInMenu
        this.type = data.attributes.type
        this.locale = data.attributes.locale
        this.number = data.attributes.number
    }
}