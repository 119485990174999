import ReactMarkdown from "react-markdown";
import {H1} from "../headings/H1";
import {ActionButton} from "../buttons/ActionButton";
import {useGlobalContext} from "../../../services/providers/GlobalProvider";
import {H3} from "../headings/H3";

type types = {
    handleDisplayForm: () => void,
    heading: string
    subHeading?: string
    text: string
}
export const FormIntro = ({heading, subHeading, text, handleDisplayForm}: types) => {
    const {pairs} = useGlobalContext()
    return <div className="card">
        {!!heading && <H1>{heading}</H1>}
        {!!subHeading && <H3>{subHeading}</H3>}
        <ReactMarkdown className="text-center text-lg">{text}</ReactMarkdown>
        <ActionButton colors={'bg-orange text-white'} text={pairs?.contactMe || 'Me contacter'}
                      callback={handleDisplayForm}/>
    </div>
}