import { ReactNode } from "react";
import { useModalContext } from "../../../services/providers/ModalProvider";

type Types = {
    children: ReactNode
}
export const Modal = ({ children }: Types) => {
    const { setModalContent } = useModalContext()
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        if (setModalContent && target.classList.contains('_lightbox-bg')) setModalContent(null)
    }

    return <div
        className="_lightbox-bg flex items-center justify-center"
        onClick={handleClick}>
        {children}
    </div>
}