//libraries
import {ApolloProvider} from "@apollo/client";

//services
//components
import {apiClient} from "./services/api/ApiClient";
import {Router} from "./Router";
import {RoutesProvider} from "./services/providers/RoutesProvider";
import {GlobalProvider} from "./services/providers/GlobalProvider";
import {CookiesProvider} from "react-cookie";
import {ErrorBoundary} from "./services/errors/ErrorBoundary";

export default function App() {
    return (
        <ErrorBoundary>
            <CookiesProvider>
                <ApolloProvider client={apiClient}>
                    <GlobalProvider>
                        <RoutesProvider>
                            <Router/>
                        </RoutesProvider>
                    </GlobalProvider>
                </ApolloProvider>
            </CookiesProvider>
        </ErrorBoundary>
    );
}
