import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {GET_QUOTES} from "../../api/queries/QuotesQueries";
import {QuoteModel} from "../../models/entities/QuoteModel";
import {QuoteDataType} from "../../api/types/PageDataTypes";
import {useCookies} from "react-cookie";
import {getNavigatorLocale} from "../../api/ApiClient";

export const useQuotes = () => {
    const [cookies] = useCookies(['adelieLocale'])
    const [quotes, setQuotes] = useState<QuoteModel[]>([])
    const {data} = useQuery(GET_QUOTES, {
        variables: {locale: (cookies?.adelieLocale || getNavigatorLocale())}
    })
    const [index, setIndex] = useState(0)

    const serializeQuotes = (data: QuoteDataType[]) => {
        const temp: QuoteModel[] = []
        data.forEach(quoteData => {
            temp.push(new QuoteModel(quoteData))
        })
        setQuotes(temp)
    }

    useEffect(() => {
        if (data && data.quotes) {
            serializeQuotes(data.quotes.data)
        }
    }, [data])

    useEffect(() => {
        const interval = quotes?.length > 0 && setInterval(() => {
            setIndex(value => ((quotes.length - 1) > value ? (value + 1) : 0))
        }, 10000)
        return () => clearInterval(interval as NodeJS.Timeout)
    }, [quotes])
    return {quotes, index}
}