import {useState} from "react";
import {sendContactFormData} from "../../api/queries/FetchQueries";

export const useSendContactData = () => {
    const [success, setSuccess] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [error, setError] = useState(false)

    const send = async (data: Record<string, string>[]) => {
        setIsSending(true)
        const response = await sendContactFormData(data)
        if (response) setIsSending(false)
        if (response.status == 'sent') {
            setSuccess(true)
        } else if (response?.error) {
            setError(true)
        }
    }

    return {success, isSending, error, send}
}