//ICONS
export const CLASSLIST_ICONS_IN_TEXT = 'text-base sm:text-xl';
export const CLASSLIST_ICONS_STANDALONE = 'text-2xl sm:text-3xl xl:text-4xl';
export const CLASSLIST_ICONS_MODALS = 'text-4xl sm:text-7xl';

//BUTTONS
export const CLASSLIST_BUTTON_BASE = "z-10 text-center text-white rounded-md px-4 py-2 font-display active:scale-90 active:brightness-90 hover:brightness-110 shadow-lg active:shadow-md transition-all"
//TITLES

//IMAGES

//SECTIONS


//FORMS
export const CLASSLIST_SELECT = "form-select appearance-none block w-full px-3 py-2 bg-white bg-clip-padding bg-no-repeat border border-2 border-dashed border-lightBlue rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
export const CLASSLIST_CHECKBOX = "flex-none mt-1 form-check-input peer appearance-none border border-orange rounded-sm bg-white checked:bg-orange checked:border-orange focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer block h-4 w-4"
export const CLASSLIST_RADIO = "flex-none mt-1 form-check-input peer appearance-none rounded-full border border-orange bg-white checked:bg-orange checked:border-orange focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer block h-4 w-4"