import React, {ReactNode} from "react";

type types = {
    children: ReactNode,
    color: string,
    width: string
}

export const SVG = ({children, color, width}: types) => {
    return (
        <div className={width + ' h-auto' + color}>
            {children}
        </div>
    );
};