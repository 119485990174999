import {LinkModel} from "./LinkModel";
import {IndexItemDataType} from "../../../api/types/IndexDataTypes";
import {Serializer} from "../../Serializer";

export class IndexItemModel {
    name: string
    slug: string
    description: string
    links: LinkModel[]

    constructor(data: IndexItemDataType) {
        this.name = data.attributes?.name
        this.description = data.attributes?.description
        this.slug = data.attributes?.slug
        this.links = Serializer.serializeLinks(data.attributes?.links)
    }
}