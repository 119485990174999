import {LinkModel} from "../pages/items/LinkModel";
import {CollaboratorModel} from "./CollaboratorModel";
import {TechnologyModel} from "./TechnologyModel";
import {Serializer} from "../Serializer";
import {ProjectDataType} from "../../api/types/EntitiesDataTypes";
import {ImageModel} from "./ImageModel";

export class ProjectModel {
    name: string
    links: LinkModel[]
    startDate?: Date
    currentReleaseDate?: Date
    collaborators: CollaboratorModel[]
    technologies: TechnologyModel[]
    extendedDescription: string
    images?: ImageModel[]
    services: string[]
    snippet: string
    languages: { name: string, icon: string }[]

    constructor(data: ProjectDataType) {
        this.name = data.attributes.name
        this.links = Serializer.serializeLinks(data.attributes.links)
        this.collaborators = Serializer.serializeCollaborators(data.attributes.collaborators.data)
        this.technologies = Serializer.serializeTechnologies(data.attributes.technologies.data)
        this.extendedDescription = data.attributes.extendedDescription
        this.images = Serializer.serializeImages(data.attributes.images.data)
        this.currentReleaseDate = data.attributes?.currentReleaseDate ? new Date(data.attributes.currentReleaseDate) : undefined
        this.startDate = data.attributes?.startDate ? new Date(data.attributes.startDate) : undefined
        this.services = Serializer.serializeServices(data.attributes.services.data)
        this.snippet = data.attributes.snippet
        this.languages = Serializer.serializeLanguages(data.attributes.languages.data)
    }
}