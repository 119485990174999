import {IndexItemModel} from "./IndexItemModel";
import {LanguageDataType,} from "../../../api/types/PageDataTypes";
import {TechnologyItemDataType} from "../../../api/types/IndexDataTypes";

export class IndexTechnologyModel extends IndexItemModel {
    languages: { icon: string; name: string, info: string }[]
    icon: string

    constructor(data: TechnologyItemDataType) {
        super(data)
        this.languages = this.serializeLanguages(data.attributes.languages.data)
        this.icon = data.attributes.reactIcon
    }

    private serializeLanguages(data: LanguageDataType[]) {
        const temp: { icon: string; name: string, info: string }[] = []
        data?.forEach(languageData => {
            const language = {
                icon: languageData.attributes.reactIcon,
                name: languageData.attributes.name,
                info: languageData.attributes.info
            }
            temp.push(language)
        })
        return temp
    }
}