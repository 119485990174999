import React, { ReactElement } from "react"
import { useEffect, useState } from "react"
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from "react-icons/io"
import { useSlider } from "../../../../services/hooks/useSlider"
import { putDomBackIntoPlace, putDomInBackground } from "../../../../services/utils/DomFunctions"

type SliderTypes = {
    children: ReactElement[]
}

export const Slider = ({ children }: SliderTypes) => {
    const { currentIndex, hasPrevious, hasNext, handleTouchEnd, handleTouchMove, handleTouchStart, slideToPrevious, slideToNext } = useSlider({ numberOfItems: (children.length - 1) })
    const [isFullScreen, setIsFullScreen] = useState(false)

    useEffect(() => {
        isFullScreen ? putDomInBackground() : putDomBackIntoPlace()
    }, [isFullScreen])

    return <div
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        className={"_slider-container " + (isFullScreen ? '_lightbox-bg' : '_is-normal')}
    >
        <IoIosArrowBack
            onClick={slideToPrevious}
            className={"_arrow-button left-0 "
                + (hasPrevious ? 'opacity-1' : 'opacity-0 pointer-event-none')}
        />
        {children.map((child, i) => {
            return React.cloneElement(child, { ...child.props, isCurrent: (currentIndex === i), handleZoom: () => setIsFullScreen(true) })
        })}
        <IoIosArrowForward
            onClick={slideToNext}
            className={"_arrow-button right-0 "
                + (hasNext ? 'opacity-1' : 'opacity-0 pointer-event-none')}
        />
        {!!(isFullScreen && setIsFullScreen) && <IoIosClose className="top-4 right-4 text-white text-6xl z-50 absolute" onClick={() => setIsFullScreen(false)} />}
    </div>
}