import {useGlobalContext} from "../../../services/providers/GlobalProvider";
import {LoaderMin} from "../../common/loaders/LoaderMin";

export const FormLoader = () => {
    const {pairs} = useGlobalContext()
    return (
        <div className="card">
            <LoaderMin height={'h-36'} width={'w-auto'}/>
            <p className="text-center text-md">{pairs?.messageSending || "Envoi en cours..."}</p>
        </div>
    )
}