import { ImageModel } from "../../../../services/models/entities/ImageModel"

type Types = {
    image: ImageModel
    isCurrent?: boolean
    handleZoom?: (e: React.MouseEvent<HTMLImageElement>) => void
}
export const SliderImage = ({ image, isCurrent, handleZoom }: Types) => {
    return <img onClick={handleZoom}
        className={'touch-none shadow-xl transition-all max-w-screen max-h-screen h-full object-cover cursor-zoom-in snap-center ' + (isCurrent ? 'opacity-1 block' : 'opacity-0 absolute')}
        src={image.medium || image.basic}
        alt={"image " + image.basic}
    />
}