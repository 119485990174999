import {ReactNode} from "react";
import {Link, useMatch, useResolvedPath} from "react-router-dom";

type types = {
    children: ReactNode,
    color: string,
    to: string
    icon: ReactNode
}

export const MobileNavButton = ({children, to, icon}: types) => {
    const resolve = useResolvedPath(to);
    const match = useMatch({path: resolve.pathname});

    return (
        <Link to={to}
              className={(match ? 'text-blue border-blue' : 'border-orange') + ' flex justify-start items-center w-full'}
        >
            <div className={(match ? 'bg-blue' : 'bg-orange') + " -ml-7 p-4 mr-8 rounded-full"}>{icon}</div>
            {children}
        </Link>
    );
};