import {ReactNode} from "react";

type types = {
    children: ReactNode,
}

export const H3 = ({children}: types) => {
    return (
        <h3 className="text-xl md:text-2xl text-center w-full md:w-auto">{children}</h3>
    );
};