import {useQuotes} from "../../../services/hooks/api/useQuotes";

export const Quotes = () => {
    const {quotes, index} = useQuotes()
    return <div
        className={"w-full flex gap-4"}>
        {quotes?.map((quote, i) => {
            return <div key={'quote' + quote.id}
                        className={"h-max flex flex-col justify-around " + (index === i ? 'animate-appear' : 'absolute opacity-0')}>
                <p className="self-start text-md xl:text-xl">❮ {quote.text} /❯</p>
                <p className="self-end">{quote.author}</p>
            </div>
        })}
    </div>
}