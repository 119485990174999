import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { Modal } from "../../components/common/modals/Modal";
import React from 'react'
import { putDomBackIntoPlace, putDomInBackground } from "../utils/DomFunctions";

type Props = {
    children?: ReactNode,
};

type Types = {
    setModalContent?: Dispatch<SetStateAction<ReactNode>>
}

const ModalContext = createContext<Types>({});

export const ModalProvider = ({ children }: Props) => {
    const [modalContent, setModalContent] = useState<ReactNode>()

    useEffect(() => {
        modalContent ? putDomInBackground() : putDomBackIntoPlace()
    }, [modalContent])
    return (
        <ModalContext.Provider
            value={{ setModalContent: setModalContent }}
        >
            {children}
            {modalContent && <Modal>{modalContent}</Modal>}
        </ModalContext.Provider>
    );
}

export const useModalContext = () => useContext(ModalContext);