import ReactMarkdown from "react-markdown";
import { ProjectModel } from "../../../services/models/entities/ProjectModel";
import { useGlobalContext } from "../../../services/providers/GlobalProvider";
import { H1 } from "../headings/H1";
import { ImagesSlider } from "../figures/sliders/ImagesSlider";
import { H2 } from "../headings/H2";
import { Tag } from "../tags/Tag";
import { TechnologyTag } from "../tags/TechnologyTag";
import { GenericIcon } from "../generics/GenericIcon";

type Types = {
    project: ProjectModel
}

export const ProjectDetails = ({ project }: Types) => {
    const {
        images,
        name,
        snippet,
        currentReleaseDate,
        technologies,
        startDate,
        extendedDescription,
        languages,
        services,
        collaborators
    } = project
    const { pairs } = useGlobalContext()

    return <>
        <div className="flex flex-col gap-8 w-full">
            <H1>{name}</H1>
            {!!snippet && <ReactMarkdown className="text-center text-xl">{snippet}</ReactMarkdown>}
        </div>
        <div className="card flex-col items-start">
            <H2>{pairs?.projectDetails_details_title}</H2>
            {!!startDate && <div className="flex items-center justify-start flex-wrap gap-2 md:gap-4 w-full">
                <p className="font-display">{pairs?.projectDetails_details_startDate} :</p>
                <p>{startDate.toLocaleDateString()}</p>
            </div>}
            {!!currentReleaseDate &&
                <div className="flex items-center justify-start flex-wrap gap-2 md:gap-4 w-full">
                    <p className="font-display">{pairs?.projectDetails_details_releaseDate} :</p>
                    <p>{currentReleaseDate.toLocaleDateString()}</p>
                </div>}
            <div className="flex items-center justify-start flex-wrap gap-2 md:gap-4 w-full">
                <p className="font-display">{pairs?.projectDetails_details_collaborators} :</p>
                {collaborators.length ?
                    collaborators.map((collaborator, i) => {
                        const link = collaborator?.links?.find(link => (link.name == 'Site' || link.name == 'LinkedIn'))
                        return <Tag key={collaborator.name} index={i} text={collaborator.name} href={link?.href} />
                    })
                    :
                    <Tag index={0} text={pairs?.personalProject || ''} />
                }
            </div>
            <div className="flex flex-wrap items-center justify-start gap-2 md:gap-4">
                <p className="font-display">{pairs?.projectDetails_details_technologies} :</p>
                {technologies?.length ? technologies.map(techno => {
                    return <TechnologyTag key={techno.name} technology={techno} />
                })
                    :
                    <p className="text-blue text-md text-center">{pairs?.noTechnology}</p>
                }
            </div>
            <div className="flex gap-2 md:gap-4 flex-wrap">
                <p className="font-display">{pairs?.projectDetails_details_languages} :</p>
                {languages?.map(language => {
                    return <GenericIcon key={language.name} width={'text-xl'} iconName={language.icon}
                        color={'text-blue'} />
                })}
            </div>
            <div className="flex flex-wrap gap-2 md:gap-4">
                <p className="font-display">{pairs?.projectDetails_details_services} :</p>
                <ul className="pl-4 list-disc flex flex-col gap-2">
                    {services?.map(service => {
                        return <li key={service}>{service}</li>
                    })}
                </ul>
            </div>
        </div>
        <div className="card">
            <H2>{pairs?.projectDetails_description_title}</H2>
            {!!extendedDescription &&
                <ReactMarkdown className="text-justify indent-4 text-md">{extendedDescription}</ReactMarkdown>}
        </div>
        {!!images && <ImagesSlider images={images} />}
    </>
}