import {ApolloClient, InMemoryCache} from "@apollo/client";


export const getNavigatorLocale = () => {
    const language = navigator.language
    return language?.split('-')?.shift()
}

export const apiClient = new ApolloClient({
    uri: process.env.REACT_APP_BASE_API_URL,
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === 'development',
});
