import {LinkModel} from "../pages/items/LinkModel";
import {Serializer} from "../Serializer";
import {TechnologyDataType} from "../../api/types/EntitiesDataTypes";

export class TechnologyModel {
    name: string
    icon: string
    links?: LinkModel[]
    languages?: { name: string; icon: string }[]
    description?: string

    constructor(data: TechnologyDataType) {
        console.log(data)
        this.name = data.attributes.name
        this.icon = data.attributes.reactIcon
        this.links = data.attributes.links
        this.languages = Serializer.serializeLanguages(data.attributes.languages?.data)
        this.description = data.attributes.description
    }
}