import {ReactNode} from "react";
import {FormProvider, useForm} from "react-hook-form";

type types = {
    classes?: string,
    id?: string
    children: ReactNode,
    onSubmit: (data: Record<string, string>[]) => void,
    formData: Record<string, string>[] | undefined
}

export const Form = ({classes, id, children, onSubmit, formData}: types) => {
    const methods = useForm({defaultValues: formData})
    return (
        <FormProvider {...methods}>
            <form className={classes} id={id} onSubmit={methods.handleSubmit(onSubmit)}>
                {children}
            </form>
        </FormProvider>
    );
};