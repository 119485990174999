import {ReactNode} from "react";

type types = {
    children: ReactNode,
}

export const H2 = ({children}: types) => {
    return (
        <h2 className={"text-orange font-display text-2xl xl:text-3xl w-full text-center"}>{children}</h2>
    );
};