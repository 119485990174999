import {ReactNode} from "react";

type types = {
    children: ReactNode,
}

export const H5 = ({children}: types) => {
    return (
        <h4 className="text-xl text-center w-full">{children}</h4>
    );
};