import {TextSectionModel} from "../../../services/models/pages/sections/TextSectionModel";
import {H2} from "../headings/H2";
import ReactMarkdown from "react-markdown";
import {RoundedLinkButton} from "../buttons/RoundedLinkButton";
import {H4} from "../headings/H4";
import {BaseImage} from "../figures/BaseImage";

type Types = {
    section: TextSectionModel
    hasCard?: boolean
}

export const TextSection = ({section, hasCard = true}: Types) => {
    return <section id={section.name}>
        <div className={hasCard ? 'card' : 'paragraph'}>
            {!!section.heading && <H2>{section.heading}</H2>}
            {!!section.subHeading && <H4>{section.subHeading}</H4>}
            <ReactMarkdown className="text-justify indent-4 text-sm md:text-md">{section.text}</ReactMarkdown>
            {!!section.buttons?.length &&
			<div className="flex justify-around items-center gap-8 flex-wrap">
                {section.buttons.map(button => {
                    return <RoundedLinkButton key={button.text} {...button}/>
                })}
			</div>
            }
        </div>
        {!!section.media && <BaseImage
			alt={section.name}
			src={section.media.medium || section.media.basic}
			kind={section.mediaFormat}
			dimensions={'max-w-xs'}
		/>
        }
    </section>
}