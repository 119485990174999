import { ImageModel } from "../../../../services/models/entities/ImageModel";
import React from "react";
import { SliderImage } from "./SliderImage";
import { Slider } from "./Slider";

type Types = {
    images: ImageModel[]
}

const ImagesSliderComponent = ({ images }: Types) => {

    return <Slider>
        {images.map((image) => {
            return <SliderImage image={image} key={'image-' + image.basic} />
        })}
    </Slider>
}

export const ImagesSlider = React.memo(ImagesSliderComponent)
