import {H5} from "../headings/H5";
import {SectionModel} from "../../../services/models/pages/sections/SectionModel";
import {TextSectionModel} from "../../../services/models/pages/sections/TextSectionModel";

type Types = {
    sections: SectionModel[]
    title: string
}

export const Summary = ({sections, title}: Types) => {
    return <section className="flex flex-col gap-8">
        {!!title && <H5>{title}</H5>}
        <ol className="border-l-2 border-blue flex flex-col gap-8">
            {sections?.map((section, i) => {
                if (section instanceof TextSectionModel) {
                    return <li key={section.name}>
                        <div className="flex flex-start items-center">
                            <div
                                className="summary-item-number">{i + 1}</div>
                            <a href={'#' + section.name}><h4 className="-mt-2">{section.heading}</h4></a>
                        </div>
                    </li>
                }
            })}
        </ol>
    </section>
}