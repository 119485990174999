import {CheckboxModel} from "../../../../services/models/forms/fields/CheckboxModel";
import {useFormContext} from "react-hook-form";
import {CLASSLIST_CHECKBOX} from "../../../../services/globals/classlists";

export const Checkbox = (field: CheckboxModel) => {
    const {name, isRequired, label, invalidMessage} = field
    const {register, formState: {errors},} = useFormContext();

    return <div>
        <div className="w-full flex gap-4">
            <input {...register(name, {required: isRequired})}
                   id={name}
                   value={name}
                   name={name}
                   type="checkbox"
                   className={CLASSLIST_CHECKBOX}
            />
            <label className="peer-checked:text-orange" htmlFor={name}>{label}</label>

        </div>
        {!!errors[name] && <p className='text-orange'>{invalidMessage}</p>}
    </div>
}