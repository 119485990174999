import {StepConditionModel} from "./StepConditionModel";
import {InputModel} from "./fields/InputModel";
import {FieldModel} from "./fields/FieldModel";
import {CheckboxListModel} from "./fields/CheckboxListModel";
import {CheckboxModel} from "./fields/CheckboxModel";
import {RadioListModel} from "./fields/RadioListModel";
import {SimpleSelectModel} from "./fields/SimpleSelectModel";
import {TextareaModel} from "./fields/TextareaModel";
import {RegionsAutoCompleteModel} from "./fields/RegionsAutoCompleteModel";
import {FieldDataType, SimpleFormDataType, StepConditionDataType} from "../../api/types/FormDataTypes";

export class SimpleFormModel {
    id: number
    heading: string | null
    fields: FieldModel[]
    stepConditions: StepConditionModel[]
    hasCaptcha: boolean

    constructor(data: SimpleFormDataType) {
        this.id = data.id
        this.heading = data.attributes.heading
        this.fields = this.serializeFields(data.attributes.fields)
        this.stepConditions = this.serializeStepConditions(data.attributes.stepConditions)
        this.hasCaptcha = data.attributes.hasCaptcha
    }

    serializeFields(data: FieldDataType[]) {
        const temp: FieldModel[] = []
        data?.forEach(fieldData => {
            switch (fieldData.__typename) {
                case "ComponentFormInput":
                    // @ts-ignore
                    temp.push(new InputModel(fieldData))
                    break
                case "ComponentFormCheckbox":
                    // @ts-ignore
                    temp.push(new CheckboxModel(fieldData))
                    break
                case "ComponentFormCheckboxList":
                    // @ts-ignore
                    temp.push(new CheckboxListModel(fieldData))
                    break
                case "ComponentFormRegionsAutoComplete":
                    // @ts-ignore
                    temp.push(new RegionsAutoCompleteModel(fieldData))
                    break
                case "ComponentFormRadioList":
                    // @ts-ignore
                    temp.push(new RadioListModel(fieldData))
                    break
                case "ComponentFormSimpleSelect":
                    // @ts-ignore
                    temp.push(new SimpleSelectModel(fieldData))
                    break
                case "ComponentFormTextarea":
                    // @ts-ignore
                    temp.push(new TextareaModel(fieldData))
                    break
            }
        })
        return temp
    }

    serializeStepConditions(data: StepConditionDataType[]) {
        const temp: StepConditionModel[] = []
        data?.forEach(conditionData => {
            temp.push(new StepConditionModel(conditionData))
        })
        return temp
    }
}