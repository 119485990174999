import * as IoIcons from "react-icons/io";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";
import * as HiIcons from "react-icons/hi";
import * as SiIcons from "react-icons/si";
import * as GoIcons from "react-icons/go";
import * as GiIcons from "react-icons/gi";
import * as TbIcons from "react-icons/tb";
import * as FiIcons from "react-icons/fi";
import * as RiIcons from "react-icons/ri";


type Types = {
    iconName: string
    color: string
    width: string
}

export const GenericIcon = ({iconName, color, width}: Types) => {
    const getIconComponent = (iconName: string) => {
        const prefix = iconName.substring(0, 2)
        switch (prefix) {
            case 'Io':
                // @ts-ignore
                return IoIcons[iconName]
            case 'Fa':
                // @ts-ignore
                return FaIcons[iconName]
            case 'Md':
                // @ts-ignore
                return MdIcons[iconName]
            case 'Bs':
                // @ts-ignore
                return BsIcons[iconName]
            case 'Bi':
                // @ts-ignore
                return BiIcons[iconName]
            case 'Hi':
                // @ts-ignore
                return HiIcons[iconName]
            case 'Si':
                // @ts-ignore
                return SiIcons[iconName]
            case 'Go':
                // @ts-ignore
                return GoIcons[iconName]
            case 'Gi':
                // @ts-ignore
                return GiIcons[iconName]
            case 'Tb':
                // @ts-ignore
                return TbIcons[iconName]
            case 'Fi':
                // @ts-ignore
                return FiIcons[iconName]
            case 'Ri':
                // @ts-ignore
                return RiIcons[iconName]
        }
    }
    const Icon = getIconComponent(iconName)
    return <Icon className={"h-auto " + color + " " + width}/>
}