import {SimpleFormModel} from "./SimpleFormModel";
import {ComplexFormDataType, SimpleFormDataType} from "../../api/types/FormDataTypes";

export class ComplexFormModel {
    forms: SimpleFormModel[]
    heading: string
    intro: string
    subHeading: string
    firstFormId: number

    constructor(data: ComplexFormDataType) {
        this.forms = this.serializeForms(data.attributes.forms.data)
        this.heading = data.attributes.heading
        this.intro = data.attributes.intro
        this.subHeading = data.attributes.subheading
        this.firstFormId = data.attributes.firstForm.data.id
    }

    serializeForms(data: SimpleFormDataType[]) {
        const temp: SimpleFormModel[] = []
        data?.forEach(formData => {
            temp.push(new SimpleFormModel(formData))
        })
        return temp
    }
}