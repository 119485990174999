import {SectionModel} from "./SectionModel";
import {CallToActionDataType} from "../../../api/types/PageDataTypes";

export class CallToActionModel extends SectionModel {
    buttonText: string;
    text: string;
    path: string | null
    link: string | null

    constructor(data: CallToActionDataType) {
        super(data)
        this.buttonText = data.button.text
        this.text = data.text
        this.path = data.button.page?.data?.attributes?.path
        this.link = data.button?.link
    }
}