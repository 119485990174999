import {FormSectionModel} from "../../../services/models/pages/sections/FormSectionModel";
import {H3} from "../headings/H3";
import {ComplexForm} from "../forms/ComplexForm";
import {GenericComponent} from "../generics/GenericComponent";

type Types = {
    section: FormSectionModel
}

export const FormSection = ({section}: Types) => {
    return <section id={section.name}>
        {!!section.heading && <H3>{section.heading}</H3>}
        {!!section.complexForm && <ComplexForm form={section.complexForm}/>}
        {!!section.specificComponent && <GenericComponent name={section.specificComponent}/>}
    </section>
}