import {useGlobalContext} from "../../../services/providers/GlobalProvider";
import {GenericIcon} from "./GenericIcon";

export const ContactSlice = () => {
    const {contactLinks} = useGlobalContext()
    return <aside className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-2 gap-4">
        {contactLinks?.map(link => {
            return <a key={'contact-' + link.name} href={link.href} target="_blank" rel="noreferrer">
                <div
                    className={"bg-white rounded-xl shadow-xl text-orange text-md p-4 flex flex-col gap-2 items-center hover:bg-gray-100 transition-all"}>
                    <GenericIcon iconName={link.icon} color={'text-orange'} width={'text-4xl'}/>
                    <h5>{link.name}</h5>
                </div>
            </a>
        })}
    </aside>
}