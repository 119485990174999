import {PageDataType, SectionDataType} from "../../api/types/PageDataTypes";
import {CallToActionModel} from "./sections/CallToActionModel";
import {FormSectionModel} from "./sections/FormSectionModel";
import {GridSectionModel} from "./sections/GridSectionModel";
import {IndexSectionModel} from "./sections/IndexSectionModel";
import {TextSectionModel} from "./sections/TextSectionModel";
import {SectionModel} from "./sections/SectionModel";

export class PageModel {
    title: string
    intro: string
    sections: SectionModel[]

    constructor(data: PageDataType) {
        this.title = data.attributes?.title
        this.intro = data.attributes.intro
        this.sections = this.serializeSections(data.attributes?.sections)
    }

    private serializeSections(data: SectionDataType[]) {
        const temp: SectionModel[] = []
        data?.forEach(sectionData => {
            switch (sectionData.__typename) {
                case "ComponentSlicesCallToActionSection":
                    // @ts-ignore
                    temp.push(new CallToActionModel(sectionData))
                    break
                case "ComponentSlicesTextSection":
                    // @ts-ignore
                    temp.push(new TextSectionModel(sectionData))
                    break
                case "ComponentSlicesIndexSection":
                    // @ts-ignore
                    temp.push(new IndexSectionModel(sectionData))
                    break
                case "ComponentSlicesGridSection":
                    // @ts-ignore
                    temp.push(new GridSectionModel(sectionData))
                    break
                case "ComponentSlicesFormSection":
                    // @ts-ignore
                    temp.push(new FormSectionModel(sectionData))
                    break
            }
        })
        return temp
    }
}