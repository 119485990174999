import {GridItemModel} from "../../../services/models/pages/items/GridItemModel";
import {GenericIcon} from "../generics/GenericIcon";
import {H5} from "../headings/H5";
import React from "react";
import {BaseImage} from "../figures/BaseImage";
import ReactMarkdown from 'react-markdown'

type Types = {
    gridItem: GridItemModel
}

const GridItemWithTextComponent = ({gridItem}: Types) => {
    const {name, snippet, cover, logo, links} = gridItem
    return <li className="flex flex-col justify-between items-center bg-white _roundedWithShadow _grid-item h-full">
        <figure className='_image-container'>
            <BaseImage
                dimensions={'_animate-zoom'}
                src={cover?.small || cover?.basic}
                alt={name}/>
            {!!logo && <BaseImage
				kind={'logo'}
				position={"absolute bottom-4 right-4"}
				src={logo.small || logo.basic}
				alt={'logo ' + name}/>
            }
        </figure>
        <div className="_text-padding">
            <H5>{name}</H5>
            <ReactMarkdown className="text-justify indent-4 text-sm">{snippet}</ReactMarkdown>
            <div className="flex items-center justify-center gap-4">
                {links?.map(link => {
                    return <a key={'icon-' + link.name} href={link.href}><GenericIcon
                        iconName={link.icon} color={'text-blue'}
                        width={'text-2xl'}/></a>
                })}
            </div>
        </div>
    </li>
}

export const GridItemWithText = React.memo(GridItemWithTextComponent)