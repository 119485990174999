import { H3 } from "../../reused/headings/H3";
import { GenericIcon } from "../../reused/generics/GenericIcon";
import ReactMarkdown from "react-markdown";
import { ActionButton } from "../../reused/buttons/ActionButton";
import { TechnologyModel } from "../../../services/models/entities/TechnologyModel";
import { useGlobalContext } from "../../../services/providers/GlobalProvider";
import { useModalContext } from "../../../services/providers/ModalProvider";
import { useCallback } from "react";
import { ModalInnerBox } from "./ModalInnerBox";
import React from "react";

type Types = {
    technology: TechnologyModel
}

export const TechnologyModal = ({ technology }: Types) => {
    const { languages, links, description, name } = technology
    const { pairs } = useGlobalContext()
    const { setModalContent } = useModalContext()

    const handleClick = useCallback(() => {
        if (setModalContent) setModalContent(null)
    }, [setModalContent])

    return <ModalInnerBox>
        <div className="flex items-center justify-center gap-4 flex-wrap">
            <H3>{name}</H3>
            {links?.map(link => {
                return <a key={name + '-' + link.name} href={link.href} target="_blank"
                    rel="noreferrer"><GenericIcon
                        width={'text-xl'} iconName={link.icon}
                        color={'text-blue'} /></a>
            })}
        </div>
        <div className="flex gap-4 flex-wrap">
            {languages?.map(language => {
                return <GenericIcon key={language.name} width={'text-3xl'} iconName={language.icon}
                    color={'text-blue'} />
            })}
        </div>
        {!!description && <ReactMarkdown className="text-justify indent-4 text-md">{description}</ReactMarkdown>}
        <ActionButton text={pairs?.close || ''} colors={'bg-blue'} callback={handleClick} />
    </ModalInnerBox>
}