import React from "react";

const PlaceholderComponent = () => {
    return <svg className="w-52 h-52 mx-auto" version="1.1" viewBox="0 0 124.37 124.87"
                xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-.99755 -3.7502)">
            <circle className="fill-gray-50" id="sun" cx="81.075" cy="51.358" r="15.749"/>
            <circle className="fill-white" id="belly" cx="55.811" cy="64.164" r="23.622"/>
            <path className="fill-gray-300" id="right-wing"
                  d="m95.068 58.583c-4.0452 1.7214-12.448 3.1014-16.226 0.33459 0.60564 2.709 0.75546 5.7326 0.3962 8.2674 8.6569 0.52671 13.75-4.4962 15.83-8.602z"
            />
            <path className="fill-gray-300" id="left-wing"
                  d="m38.531 48.036c1.3533 7.6769 8.5698 14.888 16.634 15.956 0 0 4.5203-1.2643 7.4551-3.9631 2.9347-2.6988 5.5493-7.9225 1.4239-8.2459-3.5309 1.6691-7.0139 2.6161-10.899 2.4585s-9.442-1.3414-14.613-6.2054z"
            />
            <path id="body-up"
                  className="fill-gray-300"
                  d="m76.698 45.077c-1.4314-0.0223-2.9484 0.1656-4.2922 0.59272-2.6877 0.85425-5.464 4.5301-8.5434 6.1556-6.9156 3.0803-7.4366 6.3039-8.5553 11.834-1.5839 5.3187-13.329 20.05-27.766 20.497 35.077 17.19 40.894-19.016 42.96-23.35 2.0664-4.334 7.3991-3.5703 10.444-7.5073 2.3545-3.0443 4.1438-4.6749 7.8796-5.6658-0.27455-0.52424-1.1059-0.88429-2.2428-0.97772-1.6137-0.13261-4.0981-0.0836-6.104-0.91053-1.0029-0.41348-2.3488-0.64591-3.7801-0.66818z"
            />
        </g>
    </svg>
}

export const Placeholder = React.memo(PlaceholderComponent)