import React from "react";
import {QuotesAnimation} from "./QuotesAnimation";
import {ContactSlice} from "./ContactSlice";

type Types = {
    name: string
}

const Components = {
    QuotesAnimation,
    ContactSlice
}

export const GenericComponent = ({name}: Types) => {
    // @ts-ignore
    const Component = Components[name]
    return <Component/>
}