import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {BaseLayout} from "./components/common/layout/BaseLayout";
import {ArticlePage} from "./pages/ArticlePage";
import {useRoutes} from "./services/hooks/api/useRoutes";
import {IndexPage} from "./pages/IndexPage";
import {DetailsPage} from "./pages/DetailsPage";
import {NotFoundPage} from "./pages/NotFoundPage";
import {BasePage} from "./pages/BasePage";
import {useEffect} from "react";
import {useLocale} from "./services/hooks/useLocale";

export const Router = () => {
    const {routes} = useRoutes()
    const location = useLocation()
    const navigate = useNavigate()
    const {currentLocale} = useLocale()
    useEffect(() => {
        if (routes?.length && location.pathname == '/') {
            const indexRoute = routes.find(route => (route.number == 0 && route.locale == currentLocale))
            indexRoute && navigate(indexRoute.path)
        }
    }, [routes])
    return <Routes>
        <Route path="/" element={<BaseLayout/>}>
            {routes?.map((route) => {
                    switch (route.type) {
                        case 'base':
                            return <Route key={route.name} path={route.path} element={<BasePage/>}/>
                        case 'details':
                            return <Route key={route.name} path={route.path} element={<DetailsPage/>}/>
                        case 'article':
                            return <Route key={route.name} path={route.path} element={<ArticlePage/>}/>
                        case 'index':
                            return <Route key={route.name} path={route.path} element={<IndexPage/>}/>
                    }
                }
            )}
            <Route path="*" element={<NotFoundPage/>}/>
        </Route>
    </Routes>
}