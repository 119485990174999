import {FieldModel} from "./FieldModel";
import {CheckboxListDataType} from "../../../api/types/FormDataTypes";

export class CheckboxListModel extends FieldModel {
    checkboxes: { label: string; value: string }[]

    constructor(data: CheckboxListDataType) {
        super(data)
        this.checkboxes = data.checkboxes
    }
}