import "./index.css";
import {Suspense} from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {LoaderFull} from "./components/common/loaders/LoaderFull";

const rootElement = document.getElementById("root");
const root = rootElement ? createRoot(rootElement) : null;

root?.render(
    <Suspense fallback={<LoaderFull/>}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Suspense>
)
