import {IndexItemModel} from "./IndexItemModel";
import {ProjectItemDataType} from "../../../api/types/IndexDataTypes";
import {ImageModel} from "../../entities/ImageModel";
import {TechnologyModel} from "../../entities/TechnologyModel";
import {Serializer} from "../../Serializer";

export class IndexProjectModel extends IndexItemModel {
    startDate: Date | undefined
    currentReleaseDate: Date | undefined
    mobileScreencap?: ImageModel
    desktopScreencap?: ImageModel
    technologies: TechnologyModel[]
    collaborators: string[]

    constructor(data: ProjectItemDataType) {
        console.log(data)
        super(data)
        this.currentReleaseDate = data.attributes?.currentReleaseDate ? new Date(data.attributes.currentReleaseDate) : undefined
        this.startDate = data.attributes?.startDate ? new Date(data.attributes.startDate) : undefined
        this.mobileScreencap = data.attributes?.mobileScreencap?.data && new ImageModel(data.attributes.mobileScreencap.data)
        this.desktopScreencap = data.attributes?.desktopScreencap?.data && new ImageModel(data.attributes.desktopScreencap.data)
        this.technologies = Serializer.serializeTechnologies(data.attributes.technologies?.data)
        this.collaborators = this.serializeCollaborators(data.attributes.collaborators?.data)
    }

    private serializeCollaborators = (data: { attributes: { name: string } }[]) => {
        const temp: string[] = []
        data?.forEach(collaboratorData => {
            temp.push(collaboratorData.attributes.name)
        })
        return temp
    }
}