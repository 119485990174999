import { TechnologyTag } from "../tags/TechnologyTag";
import ReactMarkdown from "react-markdown";
import { IndexTechnologyModel } from "../../../services/models/pages/items/IndexTechnologyModel";
import { TechnologyImageFrame } from "../figures/TechnologyImageFrame";
import { H3 } from "../headings/H3";
import { RoundedLinkButton } from "../buttons/RoundedLinkButton";
import { useGlobalContext } from "../../../services/providers/GlobalProvider";

type Types = {
    technology: IndexTechnologyModel
}
export const TechnologyItem = ({ technology }: Types) => {
    const { pairs } = useGlobalContext()
    const {
        name,
        description,
        slug,
        links, languages, icon
    } = technology

    return <article id={'techno-' + slug}>
        <TechnologyImageFrame icon={icon} color={'text-blue'} />
        <div
            className="item-details">
            <div className="flex gap-4 items-center justify-between">
                <H3>{name}</H3>
                {!!links.length && <RoundedLinkButton
                    text={pairs?.learnMore || ''}
                    page={''}
                    link={links[0].href}
                    color={'hidden md:block bg-orange'} />}
            </div>
            <div className="flex gap-4">
                {languages.map(language => {
                    return <TechnologyTag key={'tag-' + language.name} technology={language} />
                })}
            </div>
            <ReactMarkdown className="text-justify indent-4 text-md">{description}</ReactMarkdown>
            {!!links.length && <RoundedLinkButton
                text={pairs?.learnMore || ''}
                page={''}
                link={links[0].href}
                color={'md:hidden block bg-orange'} />}
        </div>
    </article>
}