import {gql} from "@apollo/client";

export const GET_GLOBAL = gql`
query getGlobal($locale:I18NLocaleCode!) {
    global(locale:$locale) {
        data {
            attributes {
                pairs(pagination: {limit: 200}) {
                    key
                    value
                }
                contactLinks(pagination: {limit: 200}) {
                    key
                    value
                }
            }
        }
    }
}`