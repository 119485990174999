import {CallToActionModel} from "../../../services/models/pages/sections/CallToActionModel";
import {H2} from "../headings/H2";
import ReactMarkdown from "react-markdown";
import {RoundedLinkButton} from "../buttons/RoundedLinkButton";

type Types = {
    section: CallToActionModel
    key: string
}
export const CallToActionSection = ({section}: Types) => {
    const {heading, text, link, name, path, buttonText} = section
    return <section id={name}>
        <div className="flex flex-col justify-center items-center gap-4 bg-lightBlue rounded-md shadow-xl p-8">
            {!!heading && <H2>{heading}</H2>}
            {!!text && <ReactMarkdown className="text-center text-md">{text}</ReactMarkdown>}
            <RoundedLinkButton text={buttonText} page={path} link={link} color={'bg-blue'}/>
        </div>
    </section>
}