import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useGlobalContext } from "../../../../services/providers/GlobalProvider";

const KEY = process.env.REACT_APP_HCAPTCHA_KEY || ''

export const HCaptchaInput = () => {
    const { pairs } = useGlobalContext()
    const { setValue, formState: { errors, }, setError } = useFormContext();
    const hcaptchaRef = useRef<HCaptcha>(null);

    useEffect(() => {
        return () => {
            hcaptchaRef?.current?.removeCaptcha()
            hcaptchaRef?.current?.setState(null)
        }
    }, [])

    const onError = () => {
        setError('token', { type: 'custom', message: pairs?.hcaptchaErrorMessage })
        hcaptchaRef?.current?.resetCaptcha()
    }

    const onExpire = () => {
        setError('token', {
            type: 'custom',
            message: pairs?.hcaptchaErrorMessage
        })
        hcaptchaRef?.current?.resetCaptcha()
    }

    const onVerify = (token: string) => {
        if (token) setValue('token', token)
        else setError('token', {
            type: 'custom',
            message: pairs?.hcaptchaErrorMessage
        })
    }

    return (
        <div>
            <HCaptcha
                sitekey={KEY}
                onVerify={onVerify}
                onError={onError}
                onExpire={onExpire}
            />
            {!!errors?.token &&
                <p className="text-orange text-xs">{pairs?.hcaptchaErrorMessage}</p>}
        </div>
    )
}