import {useState} from "react";
import {ComplexFormModel} from "../models/forms/ComplexFormModel";
import {SimpleFormModel} from "../models/forms/SimpleFormModel";
import {useSendContactData} from "./api/useSendContactData";

const addVisitedStep = (steps: SimpleFormModel[], newStep: SimpleFormModel) => {
    return !steps.includes(newStep) ? [...steps, newStep] : steps
}

const findNextStepInForm = (form: ComplexFormModel, nextStepId: number) => {
    return form.forms.find((step: SimpleFormModel) => step.id === nextStepId)
}

export const useComplexForm = (form: ComplexFormModel, firstForm: SimpleFormModel | undefined) => {
    const [displayIntro, setDisplayIntro] = useState(true)
    const [visitedSteps, setVisitedSteps] = useState<SimpleFormModel[]>(firstForm ? [firstForm] : [])
    const [formData, setFormData] = useState<Record<string, string>[]>()
    const [currentStep, setCurrentStep] = useState<SimpleFormModel | undefined>(firstForm)
    const {success, error, send, isSending} = useSendContactData()

    const onStepSubmit = (stepData: Record<string, string>[]) => {
        if (form && currentStep) {
            setFormData(stepData)
            const nextStep: SimpleFormModel | undefined = getNextStep(stepData)
            if (nextStep) {
                setVisitedSteps(steps => addVisitedStep(steps, nextStep))
                setCurrentStep(nextStep)
            }
        }
    }

    const handlePrevious = () => {
        if (firstForm && currentStep && visitedSteps.length > 0) {
            if (currentStep.id === firstForm.id) {
                setDisplayIntro(true)
            } else {
                setCurrentStep(getPreviousStep())
                setVisitedSteps(steps => steps.slice(0, -1))
            }
        }
    }

    const getPreviousStep = () => {
        return currentStep && visitedSteps[(visitedSteps.indexOf(currentStep) - 1)]
    }

    const getNextStep = (data: Record<string, string>[]) => {
        let nextStep: SimpleFormModel | undefined = currentStep
        if (form && currentStep && currentStep.stepConditions) {
            if (currentStep.stepConditions.length === 1) {
                const singleCondition = currentStep.stepConditions[0]
                nextStep = findNextStepInForm(form, singleCondition.formId) || currentStep
            } else {
                currentStep.stepConditions?.forEach((condition) => {
                    // @ts-ignore
                    if (data[condition.inputName] == condition.inputValue) {
                        nextStep = findNextStepInForm(form, condition.formId) || currentStep
                    }
                })
            }
        }
        return nextStep
    }

    const onFinalSubmit = (data: Record<string, string>[]) => {
        setFormData(data)
        // @ts-ignore
        if (data?.token && data?.agreement) send(data)
        else return
    }

    const handleDisplayForm = () => {
        setDisplayIntro(false)
    }

    return {
        isSending,
        error,
        success,
        formData,
        currentStep,
        displayIntro,
        handlePrevious,
        onFinalSubmit,
        handleDisplayForm,
        onStepSubmit
    }
}