import React from "react";
import {Placeholder} from "./svg/Placeholder";

type Types = {
    src?: string
    alt: string
    kind?: 'roundedWithShadow' | 'roundedFull' | 'logo' | 'transparent'
    position?: string
    dimensions?: string
}

const BaseImageComponent = ({kind = 'transparent', src, alt, position = '', dimensions = ''}: Types) => {
    return src ? <img
            className={'will-change-auto animate-page-appear w-full h-full object-cover _' + kind + ' ' + position + ' ' + dimensions}
            src={src} alt={alt}/>
        : <div className={"h-full w-full bg-gray-100 " + kind}><Placeholder/></div>

}

export const BaseImage = React.memo(BaseImageComponent)