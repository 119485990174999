import {SectionModel} from "../services/models/pages/sections/SectionModel";
import {CallToActionModel} from "../services/models/pages/sections/CallToActionModel";
import {CallToActionSection} from "../components/reused/sections/CallToActionSection";
import {H1} from "../components/reused/headings/H1";
import {H3} from "../components/reused/headings/H3";
import {IndexSectionModel} from "../services/models/pages/sections/IndexSectionModel";
import {IndexSection} from "../components/reused/sections/IndexSection";
import {useLocation} from "react-router-dom";
import {usePage} from "../services/hooks/api/usePage";

const renderSection = (section: SectionModel) => {
    return section instanceof CallToActionModel && <CallToActionSection key={section.name} section={section}/> ||
        section instanceof IndexSectionModel &&
		<IndexSection key={section.name} section={section}/>
}

export const IndexPage = () => {
    const location = useLocation()
    const {content} = usePage(location.pathname)
    return <div className="page">
        <div className="flex flex-col gap-2 md:gap-4">
            <H1>{content?.title}</H1>
            <H3>{content?.intro}</H3>
        </div>
        <div className="w-full flex flex-col gap-20">
            {content?.sections?.map(section => {
                return renderSection(section)
            })
            }
        </div>
    </div>
}