//libraries
import { MobileNavBar } from './navigation/MobileNavBar';

//stylesheets
import './Header.css';
import { SVG } from "../../reused/figures/svg/SVG";
import { useRoutesContext } from "../../../services/providers/RoutesProvider";
import { Link } from "react-router-dom";
import { DesktopNavBar } from "./navigation/DesktopNavBar";
import { AnimatedLogoSmall } from "../../reused/figures/svg/AnimatedLogoSmall";

export const Header = () => {
    const { routes } = useRoutesContext()
    return (
        <header role="header">
            <div className="flex gap-8 items-center">
                <Link to={routes?.length ? routes[0].path : '/'}>
                    <SVG color={'text-black'} width={'w-16'}><AnimatedLogoSmall /></SVG>
                </Link>
                <div className="justify-self-start font-display text-xl">Adélie.C</div>
            </div>
            <DesktopNavBar />
            <MobileNavBar />
        </header>
    );
}