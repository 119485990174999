import {gql} from "@apollo/client";
import {
    FCallToActionSection,
    FFormSection,
    FGridSection,
    FIndexSection,
    FSpecificComponent,
    FTextSection
} from "./SlicesComponents";

export const FPage = gql`
    ${FIndexSection}
    ${FTextSection}
    ${FGridSection}
    ${FFormSection}
    ${FSpecificComponent}
    ${FCallToActionSection}
    fragment Page on PageEntity {
        attributes {
            title
            name
            type
            intro
            sections {
              __typename
              ...IndexSection
              ...TextSection
              ...FormSection
              ...GridSection
              ...SpecificComponentSlice
              ...CallToActionSection
            }
        }
    }`