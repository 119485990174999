import { useEffect, useState } from "react";
import { fetchIndexItems } from "../../api/queries/FetchQueries";
import { IndexProjectModel } from "../../models/pages/items/IndexProjectModel";
import { IndexItemModel } from "../../models/pages/items/IndexItemModel";
import { IndexTechnologyModel } from "../../models/pages/items/IndexTechnologyModel";
import { getNavigatorLocale } from "../../api/ApiClient";
import { useCookies } from "react-cookie";
import { ProjectItemDataType } from "../../api/types/IndexDataTypes";

export const useFetchIndex = (query: string, queryContent: string) => {
    const [items, setItems] = useState<IndexItemModel[]>()
    const [cookies] = useCookies(['adelieLocale'])

    useEffect(() => {
        const fetchItems = async () => {
            const data = await fetchIndexItems(query, queryContent, (cookies?.adelieLocale || getNavigatorLocale()))
            if (data?.data && data.data[query]?.data?.length) setItems(serializeItems(data.data[query].data))
        }
        if (query) {
            fetchItems()
        }
    }, [query])

    const serializeItems = (data: ProjectItemDataType[] | IndexTechnologyModel[]) => {
        const temp: IndexItemModel[] = []
        data?.forEach(itemData => {
            // @ts-ignore
            const item = query == 'projects' ? new IndexProjectModel(itemData) : new IndexTechnologyModel(itemData)
            temp.push(item)
        })
        return temp
    }

    return { items }
}