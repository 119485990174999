import {FormSectionDataType} from "../../../api/types/PageDataTypes";
import {ComplexFormModel} from "../../forms/ComplexFormModel";
import {SimpleFormModel} from "../../forms/SimpleFormModel";
import {SectionModel} from "./SectionModel";

export class FormSectionModel extends SectionModel {
    specificComponent: string
    complexForm: ComplexFormModel
    simpleForm: SimpleFormModel

    constructor(data: FormSectionDataType) {
        super(data)
        this.specificComponent = data?.specificComponent?.data?.attributes?.name
        this.complexForm = data?.complexForm?.data && new ComplexFormModel(data.complexForm.data)
        this.simpleForm = data?.simpleForm?.data && new SimpleFormModel(data.simpleForm.data)
    }
}