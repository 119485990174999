import {GenericIcon} from "../generics/GenericIcon";
import {InView} from "react-intersection-observer";
import {useState} from "react";

type types = {
    icon: string,
    color: string
}

export const TechnologyImageFrame = ({icon, color}: types) => {
    const [animate, setAnimate] = useState(false)
    const handleViewChange = (inView: boolean) => {
        inView ? setAnimate(true) : setAnimate(false)
    }
    return <InView onChange={handleViewChange} threshold={1} rootMargin={'100px 0px -100px 0px'}>
        <figure className={color + ' group w-44 mx-12 pt-12'}>
            <ul className={'flex relative mt-20 w-full'}>
                <li className="absolute bottom-[120%] -left-[7%] skew-x-[15deg] -rotate-12 w-20 h-12 bg-gray-400"></li>
                <GenericIcon iconName={icon}
                             width={'w-24 absolute left-[20%] top-0 -translate-y-full z-0 transition-transform ' + (animate ? '-translate-y-[200%] md:-translate-y-full' : '')}
                             color={'text-blue'}/>
                <li className="skew-y-12 w-20 h-20 bg-gray-400"></li>
                <li className="-skew-y-12 w-20 h-20 bg-gray-500"></li>
                <li className="absolute bottom-[108%] right-[4%] -skew-x-[30deg] -rotate-12 w-20 h-12 bg-gray-400"></li>
            </ul>
            <div className="w-full overflow-y-scroll rounded-md">
            </div>
        </figure>
    </InView>
}