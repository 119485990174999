import { Modal } from "./Modal";
import { H2 } from "../../reused/headings/H2";
import panicMp4 from "../../../assets/img/panic.mp4"
import panicWebM from "../../../assets/img/panic.webm"
import { RoundedLinkButton } from "../../reused/buttons/RoundedLinkButton";
import { Video } from "../../reused/figures/Video";
import { ModalInnerBox } from "./ModalInnerBox";

type types = {
    hasError: boolean
}

export const ErrorModal = ({ hasError }: types) => {
    return hasError ? <Modal>
        <ModalInnerBox>
            <H2>Uh oh... Il y a eu un petit souci.</H2>
            <Video srcMp4={panicMp4} srcWebM={panicWebM} />
            <p className="text-center text-md">Pas de panique, c'est juste un petit bug. Cliquez ci-dessous pour retourner
                sur la bonne banquise.</p>
            <RoundedLinkButton text={'Rentrer à bon port'} page={'/'} link={''} color={'bg-orange'} />
        </ModalInnerBox>
    </Modal> : <></>
}
