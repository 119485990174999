type Types = {
    text: string
    index: number
    href?: string
}

const COLORS = [
    'border-blue text-blue',
    'border-orange text-orange',
    'border-oxfordBlue text-oxfordBlue'
]

export const Tag = ({text, index, href}: Types) => {
    return <a href={href} target="_blank" rel="noreferrer">
        <div
            className={COLORS[index % COLORS.length] + ' border border-2 text-xs px-3 py-1 text-center rounded-full'}>{text}</div>
    </a>
}