import { InView } from "react-intersection-observer";
import { useState } from "react";
// @ts-ignore
import { ImageModel } from "../../../services/models/entities/ImageModel";
import { PlaceholderScreen } from "./svg/PlaceholderScreen";


type types = {
    desktopImage?: ImageModel
    mobileImage?: ImageModel
    projectName: string
}
export const ProjectImageFrame = ({ desktopImage, mobileImage, projectName }: types) => {
    const [scroll, setScroll] = useState(false)
    const handleViewChange = (inView: boolean) => {
        inView ? setScroll(true) : setScroll(false)
    }
    return <InView onChange={handleViewChange} threshold={1} rootMargin={'100px 0px -100px 0px'}>
        <figure
            className="index-figure">
            {desktopImage ? <div
                className="w-[80vw] h-56 md:h-64 md:w-96 overflow-hidden rounded-md shadow-2xl border-8 border-b-gray-500 border-t-gray-400 border-r-gray-500 border-l-gray-400">
                <img
                    className={(scroll ? 'object-bottom' : 'object-top') + ' screenframe-base'}
                    src={desktopImage.basic}
                    alt={"desktop screencap " + projectName} />
            </div>
                : mobileImage ?
                    <div
                        className="overflow-hidden h-64 w-32 rounded-md shadow-2xl border-4 border-b-gray-500 border-t-gray-400 border-r-gray-500 border-l-gray-400">
                        <img
                            className={(scroll ? 'object-bottom' : 'object-top') + ' screenframe-base'}
                            src={mobileImage.basic}
                            alt={"mobile screencap " + projectName} />
                    </div>
                    :
                    <PlaceholderScreen />
            }
        </figure>
    </InView>
}