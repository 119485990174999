import {ReactNode} from "react";

type types = {
    children: ReactNode,
}

export const H1 = ({children}: types) => {
    return (
        <h1 className="font-display text-oxfordBlue text-3xl md:text-4xl xl:text-5xl text-center w-full">{children}</h1>
    );
};