import {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_GLOBAL} from "../../api/queries/GlobalQueries";
import {Serializer} from "../../models/Serializer";
import {getNavigatorLocale} from "../../api/ApiClient";
import {useCookies} from "react-cookie";
import {LinkModel} from "../../models/pages/items/LinkModel";

export const useGlobal = () => {
    const [pairs, setPairs] = useState<{ [x: string]: string; }>()
    const [contactLinks, setContactLinks] = useState<LinkModel[]>()
    const [cookies] = useCookies(['adelieLocale'])

    const {data} = useQuery(GET_GLOBAL, {
        variables: {locale: (cookies?.adelieLocale || getNavigatorLocale())}
    })

    useEffect(() => {
        if (data?.global?.data?.attributes) {
            setPairs(Serializer.serializePairs(data.global.data.attributes.pairs))
            setContactLinks(Serializer.serializeContactLinks(data.global.data.attributes.contactLinks))
        }
    }, [data])

    return {pairs, contactLinks}
}