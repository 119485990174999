//libraries

import {AnimatedLogo} from "../../reused/figures/svg/AnimatedLogo";

type Types = {
    width?: string
    height?: string
}
export const LoaderMin = ({width, height}: Types) => {
    return (
        <div
            className={(height ? height : "h-full") + " animate-page-appear flex flex-col justify-center items-center " + (width ? width : 'w-full')}>
            <AnimatedLogo onlyOnHover={false}/>
        </div>
    );
}