import {GridSectionModel} from "../../../services/models/pages/sections/GridSectionModel";
import {useFetchGridItems} from "../../../services/hooks/api/useFetchGridItems";
import {H2} from "../headings/H2";
import {RoundedLinkButton} from "../buttons/RoundedLinkButton";
import {GridItemWithLink} from "../items/GridItemWithLink";
import {GridItemWithText} from "../items/GridItemWithText";

type Types = {
    section: GridSectionModel
}
export const GridSection = ({section}: Types) => {
    const {name, heading, query, button, background} = section
    const {items} = useFetchGridItems(query)
    return <section id={name} className={background}>
        {!!heading && <H2>{heading}</H2>}
        <ul className="w-full grid sm:grid-cols-2 xl:grid-cols-3 gap-10">
            {items?.map(item => {
                return item.slug ? <GridItemWithLink key={item.name} gridItem={item} linkBase={name}/> :
                    <GridItemWithText key={item.name} gridItem={item}/>
            })}
        </ul>
        {!!button && <RoundedLinkButton text={button.text} page={button.page} color={button.color} link={button.link}/>}
    </section>
}