import {FieldModel} from "./FieldModel";
import {TextareaDataType} from "../../../api/types/FormDataTypes";

export class TextareaModel extends FieldModel {
    placeholder: string

    constructor(data: TextareaDataType) {
        super(data)
        this.placeholder = data.placeholder
    }
}