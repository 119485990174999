//libraries

import {AnimatedLogo} from "../../reused/figures/svg/AnimatedLogo";

export const LoaderFull = () => {
    return (
        <div
            className="animate-page-appear fixed top-0 left-0 flex flex-col justify-center items-center h-screen w-screen">
            <div className="w-1/3 sm:w-1/4 xl:w-1/5">
                <AnimatedLogo onlyOnHover={false}/>
            </div>
        </div>
    );
}
