type Types = {
    text: string
    callback?: () => void
    isSubmit?: boolean
    colors: string
}

export const ActionButton = ({text, callback, isSubmit = false, colors}: Types) => {
    const handleClick = () => {
        if (callback) callback()
    }

    return isSubmit ? <button className={'btn-rounded ' + colors} type="submit">{text}</button> :
        <button type="button" className={'btn-rounded ' + colors} onClick={handleClick}>{text}</button>
}