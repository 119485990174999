import {ReactNode} from "react";

type types = {
    children: ReactNode,
}

export const H4 = ({children}: types) => {
    return (
        <h4 className="text-center text-lg md:text-xl xl:text-2xl w-full">{children}</h4>
    );
};