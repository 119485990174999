import {IndexSectionDataType} from "../../../api/types/PageDataTypes";
import {SectionModel} from "./SectionModel";

export class IndexSectionModel extends SectionModel {
    query: string
    variableName: string
    variableValue: string
    mediaComponent: string
    queryContent: string;

    constructor(data: IndexSectionDataType) {
        super(data)
        this.query = data.query
        this.variableName = data.variableName
        this.variableValue = data.variableValue
        this.mediaComponent = data.mediaComponent
        this.queryContent = data.queryContent
    }
}