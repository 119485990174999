//libraries

//services
import {useToggle} from '../../../../services/hooks/useToggle';

//components
import {ICON_MENU_CLOSE, ICON_MENU_OPEN,} from '../../../../services/globals/icons';
import {MobileNavButton} from "./MobileNavButton";
import {IconButton} from "../../../reused/buttons/IconButton";
import {useRoutesContext} from "../../../../services/providers/RoutesProvider";
import {GenericIcon} from "../../../reused/generics/GenericIcon";
import {useCookies} from "react-cookie";
import {LanguageButtons} from "../../../reused/buttons/LanguageButtons";

export const MobileNavBar = () => {
    const [cookies] = useCookies(['adelieLocale'])
    const {routes} = useRoutesContext()
    const {isOn, toggle} = useToggle();
    return (
        <div className="block xl:hidden">
            <div className="flex gap-8 items-center">
                <LanguageButtons/>
                <IconButton
                    callback={toggle}
                    color={''}
                >
                    {isOn ? ICON_MENU_OPEN : ICON_MENU_CLOSE}
                </IconButton>
            </div>
            <nav className={'_sliding-menu ' + (isOn ? ' _slide-in' : '')}>
                <ul className="max-w-max h-full font-heading text-xl flex flex-col gap-4 border-l-2 border-orange justify-between justify-center items-center">
                    {routes?.map((route) => {
                        if (route.isInMenu && route.locale == cookies.adelieLocale) return (
                            <li key={route.name} onClick={toggle} className="w-full">
                                <MobileNavButton
                                    to={route.path}
                                    color={'text-oxfordBlue'}
                                    icon={<GenericIcon color="text-white" iconName={route.icon} width={'w-5'}/>}
                                >
                                    {route.name}
                                </MobileNavButton>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
}
