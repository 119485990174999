import React, {ReactNode} from "react";

type types = {
    children: ReactNode,
    color: string,
    callback: () => void
}

export const IconButton = ({children, color, callback}: types) => {
    return (
        <button
            type="button"
            onClick={callback}
            className={
                'active:animate-spin dropShadow z-10 ' + (color || 'text-secondaryDark')
            }
        >
            {children}
        </button>
    );
};