//imports icons
//imports classLists

import {CgMenuRight, CgMenuRightAlt} from "react-icons/cg";
import {FaCreativeCommons, FaCreativeCommonsBy, FaCreativeCommonsNc} from "react-icons/fa";
import {CLASSLIST_ICONS_IN_TEXT, CLASSLIST_ICONS_MODALS, CLASSLIST_ICONS_STANDALONE} from "./classlists";
import {GoLightBulb} from "react-icons/go";
import {VscWarning} from "react-icons/vsc";

/*************************
 STANDALONE ICONS
 *************************/
export const ICON_MENU_OPEN = <CgMenuRight className={CLASSLIST_ICONS_STANDALONE}/>;
export const ICON_MENU_CLOSE = <CgMenuRightAlt className={CLASSLIST_ICONS_STANDALONE}/>;
/*************************
 ICONS MIXED IN TEXT
 *************************/
export const ICON_LICENCE_COMMONS = (
    <FaCreativeCommons className={CLASSLIST_ICONS_IN_TEXT}/>
);
export const ICON_LICENCE_BY = (
    <FaCreativeCommonsBy className={CLASSLIST_ICONS_IN_TEXT}/>
);
export const ICON_LICENCE_NC = (
    <FaCreativeCommonsNc className={CLASSLIST_ICONS_IN_TEXT}/>
);
/*************************
 MODAL ICONS
 *************************/
export const ICON_DEFAULT_ERROR = <VscWarning className={CLASSLIST_ICONS_MODALS}/>;
export const ICON_INFORMATIVE_MESSAGE = (
    <GoLightBulb className={CLASSLIST_ICONS_MODALS}/>
);