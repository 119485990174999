import {GridSectionDataType} from "../../../api/types/PageDataTypes";
import {ButtonModel} from "../items/ButtonModel";
import {SectionModel} from "./SectionModel";

export class GridSectionModel extends SectionModel {
    heading: string
    query: string
    variableName: string
    variableValue: string
    button: ButtonModel | undefined
    background: string

    constructor(data: GridSectionDataType) {
        super(data)
        this.heading = data.heading
        this.query = data.query
        this.variableName = data?.variableName
        this.variableValue = data?.variableValue
        this.button = data.button && new ButtonModel(data.button)
        this.background = data?.background?.replace('_', '-')
    }
}