import {ButtonDataType, TextSectionDataType} from "../../../api/types/PageDataTypes";
import {ButtonModel} from "../items/ButtonModel";
import {ImageModel} from "../../entities/ImageModel";

export class TextSectionModel {
    name: string
    heading: string
    subHeading: string
    text: string
    buttons: ButtonModel[]
    layout: string
    media?: ImageModel
    isCard: boolean
    mediaFormat: 'roundedWithShadow' | 'roundedFull' | 'logo' | 'transparent'

    constructor(data: TextSectionDataType) {
        this.name = data.name
        this.heading = data.heading
        this.subHeading = data.subHeading
        this.text = data.text
        this.layout = data.layout
        this.isCard = data.isCard
        this.media = data?.media?.data && new ImageModel(data.media.data)
        this.buttons = this.serializeButtons(data.buttons)
        this.mediaFormat = data.mediaFormat
    }

    serializeButtons = (data: ButtonDataType[]) => {
        const temp: ButtonModel[] = []
        data?.forEach(buttonData => {
            temp.push(new ButtonModel(buttonData))
        })
        return temp
    }
}