import {useRoutesContext} from "../../../../services/providers/RoutesProvider";
import {DesktopNavButton} from "./DesktopNavButton";
import {useCookies} from "react-cookie";
import {LanguageButtons} from "../../../reused/buttons/LanguageButtons";

export const DesktopNavBar = () => {
    const [cookies] = useCookies(['adelieLocale'])
    const {routes} = useRoutesContext()
    return (
        <nav
            className=
                'hidden xl:block'>
            <ul className="flex justify-evenly items-center gap-2">
                {routes?.map((route) => {
                    if (route.isInMenu && route.locale == cookies.adelieLocale) return (
                        <li key={'desktop-' + route.name} className="">
                            <DesktopNavButton
                                to={route.path}>
                                {route.name}
                            </DesktopNavButton>
                        </li>
                    );
                })}
                <LanguageButtons/>
            </ul>
        </nav>
    );
}