import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {fetchLocales} from "../api/queries/FetchQueries";
import {useRoutesContext} from "../providers/RoutesProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {LocaleDataType} from "../api/types/PageDataTypes";

export const useLocale = () => {
    const [cookies, setCookies] = useCookies(['adelieLocale'])
    const [locales, setLocales] = useState<string[]>()
    const [currentLocale, setCurrentLocale] = useState(cookies.adelieLocale || 'fr')
    const {routes} = useRoutesContext()
    const navigate = useNavigate()
    const location = useLocation()

    const serializeLocales = (data: LocaleDataType[]) => {
        const temp: string[] = []
        data?.forEach(localeData => {
            temp.push(localeData?.code)
        })
        return temp
    }

    useEffect(() => {
        const fetchAvailableLocales = async () => {
            const data = await fetchLocales()
            if (data?.length) setLocales(serializeLocales(data))
        }
        if (!locales?.length) fetchAvailableLocales()
    }, [])

    useEffect(() => {
        if (currentLocale) {
            setCookies('adelieLocale', currentLocale)
            if (routes?.length) {
                const localizedPageData = getRouteForCurrentPage(currentLocale)
                localizedPageData && navigate(localizedPageData.path)
            }
        }
    }, [currentLocale])

    const getRouteForCurrentPage = (currentLocale: string) => {
        const currentRoute = routes?.find(route => route.path == location.pathname)
        return routes?.find(route => {
            return (route.number == currentRoute?.number) && (route.locale == currentLocale)
        })
    }

    return {currentLocale, setCurrentLocale, locales}
}