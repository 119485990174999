import {gql} from "@apollo/client";
import {FPage} from "../fragments/Collections";

export const GET_PAGE_BY_PATH = gql`
${FPage}
query getPageByPath($path:String!) {
    pages(locale:"all", sort: "number", filters: { path: { eq: $path } }) {
        data {
            ...Page
        }
    }
}`

export const GET_ROUTER_DATA = gql`
query {
    pages(locale:"all", sort: "number") {
        data {
            attributes {
                path
                name
                type
                icon
                isInMenu
                number
                locale
            }
        }
    }
}`