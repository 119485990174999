import {useEffect, useState} from "react";
import {GET_PROJECT_BY_SLUG} from "../../api/queries/ProjectsQueries";
import {useQuery} from "@apollo/client";
import {ProjectModel} from "../../models/entities/ProjectModel";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {getNavigatorLocale} from "../../api/ApiClient";

export const useProject = (slug: string | undefined) => {
    const navigate = useNavigate();
    const [cookies] = useCookies(['adelieLocale'])

    const [project, setProject] = useState<ProjectModel>()
    const {data} = useQuery(GET_PROJECT_BY_SLUG, {
        variables: {slug: slug, locale: (cookies?.adelieLocale || getNavigatorLocale())}
    })

    useEffect(() => {
        if (data?.projects?.data?.length) setProject(new ProjectModel(data.projects.data[0]))
        else if (data?.projects?.data?.length === 0) navigate('/fr/404', {replace: true})
    }, [data])
    return {project}
}