import {FieldDataType} from "../../../api/types/FormDataTypes";

export class FieldModel {
    name: string
    label: string | undefined
    invalidMessage: string
    isRequired: boolean

    constructor(data: FieldDataType) {
        this.name = data.name
        // @ts-ignore
        this.label = data?.label ? data.label : undefined
        // @ts-ignore
        this.isRequired = data.isRequired
        // @ts-ignore
        this.invalidMessage = data.invalidMessage
    }
}