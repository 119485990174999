// @ts-ignore
import penguinAloneMp4 from '../assets/img/adelie-penguin-alone.mp4'
// @ts-ignore
import penguinAloneWebM from '../assets/img/adelie-penguin-alone.webm'

import {H1} from "../components/reused/headings/H1";
import {RoundedLinkButton} from "../components/reused/buttons/RoundedLinkButton";
import {H3} from "../components/reused/headings/H3";
import {Video} from "../components/reused/figures/Video";

export const NotFoundPage = () => {
    return <div className="page">
        <div className="flex flex-wrap gap-12 items-center justify-around">
            <Video srcMp4={penguinAloneMp4} srcWebM={penguinAloneWebM}/>
            <div className="flex flex-col gap-8 w-1/2 items-center">
                <H1>- 404 -</H1>
                <H3>Banquise inconnue...</H3>
                <p className="text-xl text-center">
                    Vous vous êtes aventuré un peu loin, il n'y a pas grand-chose à voir ici...
                </p>
                <RoundedLinkButton text={'Retour en terre explorée'} page={'/fr'} link={''} color={'bg-orange'}/>
            </div>
        </div>
    </div>;
}
