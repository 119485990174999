import {useProject} from "../services/hooks/api/useProject";
import {useParams} from "react-router-dom";
import {ProjectDetails} from "../components/reused/details/ProjectDetails";

export const DetailsPage = () => {
    const {slug} = useParams()
    const {project} = useProject(slug)
    return <div className="page">
        {!!project && <ProjectDetails project={project}/>}
    </div>
}