import {H1} from "../components/reused/headings/H1";
import {H2} from "../components/reused/headings/H2";
import {GridSection} from "../components/reused/sections/GridSection";
import {TextSection} from "../components/reused/sections/TextSection";
import {FormSection} from "../components/reused/sections/FormSection";
import {CallToActionSection} from "../components/reused/sections/CallToActionSection";
import {IndexSection} from "../components/reused/sections/IndexSection";
import {GridSectionModel} from "../services/models/pages/sections/GridSectionModel";
import {FormSectionModel} from "../services/models/pages/sections/FormSectionModel";
import {CallToActionModel} from "../services/models/pages/sections/CallToActionModel";
import {IndexSectionModel} from "../services/models/pages/sections/IndexSectionModel";
import {TextSectionModel} from "../services/models/pages/sections/TextSectionModel";
import {useLocation} from "react-router-dom";
import {usePage} from "../services/hooks/api/usePage";

export const BasePage = () => {
    const location = useLocation()
    const {content} = usePage(location.pathname)
    return <div className="page">
        {content?.title && <H1>{content.title}</H1>}
        {content?.intro && <H2>{content.intro}</H2>}
        {content?.sections?.map(section => {
            return (
                section instanceof GridSectionModel && <GridSection key={section.name} section={section}/> ||
                section instanceof FormSectionModel && <FormSection key={section.name} section={section}/> ||
                section instanceof CallToActionModel && <CallToActionSection key={section.name} section={section}/> ||
                section instanceof IndexSectionModel && <IndexSection key={section.name} section={section}/> ||
                section instanceof TextSectionModel && <TextSection key={section.name} section={section}/>
            )
        })}
    </div>
}