import { IndexSectionModel } from "../../../services/models/pages/sections/IndexSectionModel";
import { useFetchIndex } from "../../../services/hooks/api/useFetchIndex";
import { IndexItemModel } from "../../../services/models/pages/items/IndexItemModel";
import { IndexProjectModel } from "../../../services/models/pages/items/IndexProjectModel";
import { ProjectItem } from "../items/ProjectItem";
import { IndexTechnologyModel } from "../../../services/models/pages/items/IndexTechnologyModel";
import { TechnologyItem } from "../items/TechnologyItem";

type Types = {
    section: IndexSectionModel
}

const renderItem = (item: IndexItemModel) => {
    return (item instanceof IndexProjectModel && <ProjectItem key={item.name} project={item} /> ||
        item instanceof IndexTechnologyModel && <TechnologyItem key={item.name} technology={item} />)
}

export const IndexSection = ({ section }: Types) => {
    const { items } = useFetchIndex(section.query, section.queryContent)
    return <section className="gap-32">
        {items?.map(item => {
            return renderItem(item)
        })}
    </section>
}