import {DynamicField} from "./fields/DynamicField";
import {Form} from "./Form";
import {FieldModel} from "../../../services/models/forms/fields/FieldModel";
import {HCaptchaInput} from "./fields/HCaptchaInput";
import {SimpleFormModel} from "../../../services/models/forms/SimpleFormModel";
import {ActionButton} from "../buttons/ActionButton";
import {useGlobalContext} from "../../../services/providers/GlobalProvider";
import {H4} from "../headings/H4";

type Types = {
    step?: SimpleFormModel,
    handlePrevious?: (() => void) | null,
    onSubmit: (data: Record<string, string>[]) => void,
    formData?: Record<string, string>[]
    hasNext: boolean
}

export const FormStep = ({
                             formData,
                             onSubmit,
                             handlePrevious,
                             step,
                             hasNext,
                         }: Types) => {
    const {pairs} = useGlobalContext()
    return (
        <Form classes={'card'} formData={formData} onSubmit={onSubmit}>
            <H4>{step?.heading}</H4>
            {step?.fields?.map((field: FieldModel) => {
                return (
                    <DynamicField key={field.name} field={field}/>
                )
            })}
            {!!step?.hasCaptcha && <HCaptchaInput/>}
            <div className="w-full flex justify-around items-center">
                {!!handlePrevious && <ActionButton
					text={pairs?.previous || ''}
					colors={'text-blue border-2 border border-blue'}
					callback={handlePrevious}/>
                }
                <ActionButton text={(hasNext ? (pairs?.next || '') : (pairs?.send || ''))}
                              colors={hasNext ? 'text-orange border-2 border border-orange' : 'text-white bg-orange'}
                              isSubmit={true}/>
            </div>
        </Form>
    )
}