import {gql} from "@apollo/client";

export const FCheckBox = gql`
fragment CheckBox on ComponentFormCheckbox {
    __typename
    label
    name
    isRequired
    invalidMessage
}`

export const FInput = gql`
fragment Input on ComponentFormInput {
    __typename
    name
    label
    invalidMessage
    type
    isRequired
}`

export const FRegionsAutoComplete = gql`
fragment RegionsAutoComplete on ComponentFormRegionsAutoComplete {
    __typename
    name
    label
    invalidMessage
    list
    isRequired
}`

export const FSimpleSelect = gql`
fragment SimpleSelect on ComponentFormSimpleSelect {
    __typename
    name
    label
    isRequired
    isMultiple
    options {
        value
        label
    }
    placeholder
}`

export const FCheckBoxes = gql`
fragment Checkboxes on ComponentFormCheckboxList {
    __typename
    name
    label
    invalidMessage
    isRequired
    checkboxes {
        label
        value
    }
}`

export const FTextarea = gql`
fragment Textarea on ComponentFormTextarea {
    __typename
    name
    label
    placeholder
    isRequired
    invalidMessage
}`

export const FRadioButtons = gql`
fragment RadioButtons on ComponentFormRadioList {
    __typename
    name
    label
    isRequired
    invalidMessage
    buttons {
        label
        value
    }
}`

export const FRegionSelect = gql`
fragment RegionSelect on ComponentFormRegionSelect {
    __typename
    name
    label
}`

export const FSingleForm = gql`
${FInput}
${FSimpleSelect}
${FCheckBox}
${FCheckBoxes}
${FTextarea}
${FRadioButtons}
${FRegionSelect}
${FRegionsAutoComplete}
fragment SingleForm on FormEntity {
    id
    attributes {
        heading
        name
        hasCaptcha
        stepConditions {
            inputName
            inputValue
            form {
                data {
                    id
                }
            }
        }
        fields {
            ...Input
            ...SimpleSelect
            ...CheckBox
            ...Checkboxes
            ...Textarea
            ...RadioButtons
            ...RegionSelect
            ...RegionsAutoComplete
        }
    }
}`

export const FComplexForm = gql`
${FSingleForm}
fragment ComplexForm on ComplexFormEntity {
    attributes {
        heading
        intro
        subheading
        forms(pagination: {limit: 200}) {
            data {
                __typename
                ...SingleForm
            }
        }
        firstForm {
            data {
                id
            }
        }
    }
}`