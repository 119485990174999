import {gql} from "@apollo/client";

export const GET_QUOTES = gql`
    query getQuotes($locale:I18NLocaleCode!) {
        quotes(locale:$locale) {
            data {
                id
                attributes {
                    author
                    text
                }
            }
        }
    }
`;