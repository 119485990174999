import {CollaboratorDataType} from "../../api/types/PageDataTypes";
import {LinkModel} from "../pages/items/LinkModel";
import {Serializer} from "../Serializer";

export class CollaboratorModel {
    name: string
    links: LinkModel[]

    constructor(data: CollaboratorDataType) {
        this.name = data.attributes.name
        this.links = Serializer.serializeLinks(data.attributes.links)
    }
}