//libraries
import {createContext, ReactNode, useContext} from "react";

//services
//components
import {useRoutes} from "../hooks/api/useRoutes";
import {RouteModel} from "../models/RouteModel";
import {LoaderFull} from "../../components/common/loaders/LoaderFull";

type Props = {
    children?: ReactNode,
};

const RoutesContext = createContext<{ routes: RouteModel[] | undefined }>({routes: undefined});

export const RoutesProvider = ({children}: Props) => {
    const {routes} = useRoutes()
    return (
        <RoutesContext.Provider
            value={{routes: routes}}
        >
            {routes ? children : <LoaderFull/>}
        </RoutesContext.Provider>
    );
}

export const useRoutesContext = () => useContext(RoutesContext);