import React from "react";

type Types = {
    srcMp4: string
    srcWebM?: string
}

const VideoComponent = ({srcMp4, srcWebM}: Types) => {
    return <video autoPlay loop muted playsInline className={'w-full max-w-xs _roundedWithShadow'}>
        <source src={srcWebM} type="video/webm"/>
        <source src={srcMp4} type="video/mp4"/>
    </video>
}

export const Video = React.memo(VideoComponent)