import {useEffect, useState} from "react";
import {fetchGridItems} from "../../api/queries/FetchQueries";
import {GridItemDataType} from "../../api/types/PageDataTypes";
import {GridItemModel} from "../../models/pages/items/GridItemModel";
import {useCookies} from "react-cookie";
import {getNavigatorLocale} from "../../api/ApiClient";

export const useFetchGridItems = (query: string) => {
    const [items, setItems] = useState<GridItemModel[]>([])
    const [cookies] = useCookies(['adelieLocale'])

    useEffect(() => {
        const fetchItems = async () => {
            const data = await fetchGridItems(query, (cookies?.adelieLocale || getNavigatorLocale()))
            if (data?.data && data.data[query]?.data?.length) setItems(serializeItems(data.data[query].data))
        }
        if (query) {
            fetchItems()
        }
    }, [query])

    const serializeItems = (data: GridItemDataType[]) => {
        const temp: GridItemModel[] = []
        data?.forEach(itemData => {
            temp.push(new GridItemModel(itemData))
        })
        return temp
    }

    return {items}
}