//libraries

//services
import { ICON_LICENCE_BY, ICON_LICENCE_COMMONS, ICON_LICENCE_NC, } from '../../../services/globals/icons';
import { useRoutesContext } from "../../../services/providers/RoutesProvider";
import { useGlobalContext } from "../../../services/providers/GlobalProvider";
import { Link } from "react-router-dom";
import { GenericIcon } from "../../reused/generics/GenericIcon";

//components

export const Footer = () => {
    const { pairs, contactLinks } = useGlobalContext()
    const { routes } = useRoutesContext()
    return (
        <footer
            role="footer"
            className="bg-white w-full text-sm flex gap-8 justify-around md:justify-between items-center px-8 py-2 flex-wrap">
            {!!routes?.length &&
                <Link to={routes[routes.length - 1].path}>{routes[routes.length - 1].name}</Link>}
            <div
                className="bg-white flex gap-4 justify-center items-center text-center font-display text-sm">
                <h4 className="">
                    {pairs?.name} {pairs?.releaseYear} {pairs?.version}
                </h4>
                <a
                    className="flex gap-2"
                    rel="license"
                    href="https://creativecommons.org/licenses/by-nc/4.0/"
                >
                    {ICON_LICENCE_COMMONS}
                    {ICON_LICENCE_BY}
                    {ICON_LICENCE_NC}
                </a>
                <span className="hidden">
                    This work is licensed under a
                    <a rel="license" href="https://creativecommons.org/licenses/by-nc/4.0/">
                        Creative Commons Attribution-NonCommercial 4.0 International License
                    </a>
                </span>
            </div>
            <div className="flex gap-2 text-xl">
                {contactLinks?.map(link => {
                    return <a key={'footer-contact-' + link.name} href={link.href} target="_blank" rel="noreferrer">
                        <GenericIcon iconName={link.icon} color={'hover:text-blue transition-all'} width={''} />
                    </a>
                })}
            </div>
        </footer>
    );
}
