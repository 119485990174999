import {gql} from "@apollo/client";
import {FImage} from "./Entities";
import {FComplexForm, FSingleForm} from "./FormFragments";

export const FButton = gql`
fragment Button on ComponentSlicesButton {
    text
    page {
        data {
            attributes {
                path
            }
        }
    }
    link
    color
}`

export const FSpecificComponent = gql`
fragment SpecificComponentSlice on ComponentSlicesComponentWithQuery {
    __typename
    name
    heading
    query
    variableName
    variableValue
}`

export const FReactComponent = gql`
fragment ReactComponent on ReactComponentEntityResponse {
    data{
        attributes {
            name
        }
    }
}`

export const FTextSection = gql`
${FButton}
${FImage}
fragment TextSection on ComponentSlicesTextSection {
    __typename
    name
    heading
    subHeading
    isCard
    media {
        data {
        ...Image
        }
    }
    text
    buttons {
    ...Button
    }
    layout
    mediaFormat
}`

export const FFormSection = gql`
${FReactComponent}
${FComplexForm}
${FSingleForm}
fragment FormSection on ComponentSlicesFormSection {
    __typename
    name
    heading
    specificComponent {
    ...ReactComponent
    }
    complexForm {
        data {
        ...ComplexForm
        }
    }
    simpleForm {
        data {
        ...SingleForm
        }
    }
}`

export const FGridSection = gql`
${FButton}
fragment GridSection on ComponentSlicesGridSection {
    __typename
    name
    heading
    query
    variableName
    variableValue
    button {
    ...Button
    }
}`

export const FCallToActionSection = gql`
${FButton}
fragment CallToActionSection on ComponentSlicesCallToActionSection {
    __typename
    name
    heading
    text
    button {
    ...Button
    }
}`

export const FIndexSection = gql`
fragment IndexSection on ComponentSlicesIndexSection {
    __typename
    name
    heading
    query
    variableName
    variableValue
    mediaComponent
    queryContent
}`