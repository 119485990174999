export const putDomInBackground = () => {
    const body = document.querySelector('body')
    const header = document.querySelector('header')
    if (body && header) {
        body.style.overflow = 'hidden'
        body.style.height = '100vh'
        header.style.zIndex = '0'
    }
}

export const putDomBackIntoPlace = () => {
    const body = document.querySelector('body')
    const header = document.querySelector('header')
    if (body && header) {
        body.style.overflow = 'auto'
        body.style.height = 'auto'
        header.style.zIndex = '50'
    }
}