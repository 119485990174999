import {GenericIcon} from "../generics/GenericIcon";
import {useCallback} from "react";
import {useModalContext} from "../../../services/providers/ModalProvider";
import {TechnologyModal} from "../../common/modals/TechnologyModal";
import {TechnologyModel} from "../../../services/models/entities/TechnologyModel";

type Types = {
    technology: TechnologyModel
}

export const TechnologyTag = ({technology}: Types) => {
    const {setModalContent} = useModalContext()

    const handleClick = useCallback(() => {
        if (setModalContent) setModalContent(<TechnologyModal technology={technology}/>)
    }, [setModalContent])

    return <div onClick={handleClick}
                className={(technology?.description ? "cursor-pointer" : "") + " flex text-md gap-2 text-blue"}>
        {!!technology?.icon && <GenericIcon iconName={technology.icon} color={''} width={''}/>}
        <div>{technology?.name}</div>
    </div>
}