import {RadioList} from "./RadioList";
import {Textarea} from "./Textarea";
import {Input} from "./Input";
import {Select} from "./Select";
import {Checkboxes} from "./Checkboxes";
import {FieldModel} from "../../../../services/models/forms/fields/FieldModel";
import {RadioListModel} from "../../../../services/models/forms/fields/RadioListModel";
import {TextareaModel} from "../../../../services/models/forms/fields/TextareaModel";
import {SimpleSelectModel} from "../../../../services/models/forms/fields/SimpleSelectModel";
import {CheckboxListModel} from "../../../../services/models/forms/fields/CheckboxListModel";
import {CheckboxModel} from "../../../../services/models/forms/fields/CheckboxModel";
import {Checkbox} from "./Checkbox";
import {InputModel} from "../../../../services/models/forms/fields/InputModel";
import {RegionsAutoCompleteModel} from "../../../../services/models/forms/fields/RegionsAutoCompleteModel";
import {RegionsAutoComplete} from "./RegionsAutoComplete";

type Types = {
    field: FieldModel
}
export const DynamicField = ({field}: Types) => {
    return (
        field instanceof RadioListModel && <RadioList {...field}/> ||
        field instanceof TextareaModel && <Textarea {...field}/> ||
        field instanceof SimpleSelectModel && <Select {...field}/> ||
        field instanceof CheckboxListModel && <Checkboxes {...field}/> ||
        field instanceof RegionsAutoCompleteModel && <RegionsAutoComplete {...field}/> ||
        field instanceof CheckboxModel && <Checkbox {...field}/> ||
        field instanceof InputModel && <Input {...field}/>) || <></>
}