import {ButtonDataType} from "../../../api/types/PageDataTypes";

export class ButtonModel {
    text: string
    page: string | null
    link: string | null
    color: string

    constructor(data: ButtonDataType) {
        this.text = data.text
        this.link = data.link
        this.page = data.page?.data?.attributes?.path
        this.color = data?.color?.replace('_', '-')
    }
}