import {H3} from "../headings/H3";
import {useGlobalContext} from "../../../services/providers/GlobalProvider";
// @ts-ignore
import penguinSlidingMp4 from "../../../assets/img/penguin-sliding.mp4"
// @ts-ignore
import penguinSlidingWebM from "../../../assets/img/penguin-sliding.webm"
import {Video} from "../figures/Video";

export const FormSuccess = () => {
    const {pairs} = useGlobalContext()
    return (
        <div className="card">
            <H3>{pairs?.form_successTitle}</H3>
            <p className="text-center text-md">{pairs?.form_successText}</p>
            <Video srcMp4={penguinSlidingMp4} srcWebM={penguinSlidingWebM}/>
        </div>
    )
}