//libraries
import {Outlet} from 'react-router-dom';
import {Header} from './Header';
import {Footer} from "./Footer";
import {BackToTopButton} from "../../reused/buttons/BackToTopButton";
import {ModalProvider} from "../../../services/providers/ModalProvider";

//services

//components

export const BaseLayout = () => {
    return (
        <ModalProvider>
            <Header/>
            <main>
                <Outlet/>
            </main>
            <BackToTopButton/>
            <Footer/>
        </ModalProvider>
    );
}
