import {useFormContext} from "react-hook-form";
import {RadioListModel} from "../../../../services/models/forms/fields/RadioListModel";

export const RadioList = (field: RadioListModel) => {
    const {buttons, label, name, invalidMessage, isRequired} = field
    const {register, formState: {errors},} = useFormContext();

    return (
        <div className="options-group">
            <legend>{label}</legend>
            {buttons?.map(radio => {
                return (
                    <div className="option-container" key={'radio-' + radio.value}>
                        <input {...register(name, {required: isRequired})}
                               id={radio.value}
                               value={radio.value}
                               name={name}
                               type="radio"
                        />
                        <label
                            htmlFor={radio.value}>{radio.label}</label>
                    </div>
                )
            })}
            {!!errors[name] && <p className='text-orange'>{invalidMessage}</p>}
        </div>
    );
};