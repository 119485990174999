import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {RouteModel} from "../../models/RouteModel";
import {GET_ROUTER_DATA} from "../../api/queries/PagesQueries";
import {RouteDataType} from "../../api/types/PageDataTypes";

export const useRoutes = () => {
    const {data} = useQuery(GET_ROUTER_DATA, {
        variables: {locale: "all"}
    })
    const [routes, setRoutes] = useState<RouteModel[]>()

    useEffect(() => {
        if (data?.pages?.data) setRoutes(serializeRoutes(data.pages.data))
    }, [data])

    const serializeRoutes = (data: RouteDataType[]) => {
        const temp: RouteModel[] = []
        data?.forEach(routeData => {
            temp.push(new RouteModel(routeData))
        })
        return temp
    }

    return {routes}
}