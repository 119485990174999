import {useFormContext} from "react-hook-form";
import {SimpleSelectModel} from "../../../../services/models/forms/fields/SimpleSelectModel";

export const Select = (field: SimpleSelectModel) => {
    const {options, label, name, invalidMessage, isRequired, isMultiple} = field
    const {register, formState: {errors},} = useFormContext();
    return (
        <div className="flex flex-col gap-4">
            <label>{label}</label>
            <select {...register(name, {required: isRequired})}
                    name={name} multiple={isMultiple}>
                <option value="">Sélectionnez {isMultiple ? 'une/des réponse(s)' : "une réponse"}</option>
                {options?.map(option => {
                    return <option key={option.value} value={option.value}>{option.label}</option>
                })}
            </select>
            {!!errors[name] && <p className='text-orange'>{invalidMessage}</p>}
        </div>
    )
}