import {BaseSectionDataType} from "../../../api/types/PageDataTypes";

export class SectionModel {
    name: string
    heading: string | null

    constructor(data: BaseSectionDataType) {
        this.name = data.name
        this.heading = data.heading
    }
}