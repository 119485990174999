import {useFormContext} from "react-hook-form";
import {CheckboxListModel} from "../../../../services/models/forms/fields/CheckboxListModel";

export const Checkboxes = (field: CheckboxListModel) => {
    const {isRequired, label, name, invalidMessage, checkboxes} = field
    const {register, formState: {errors},} = useFormContext();

    return (
        <div className="options-group">
            {!!label && <legend>{label}</legend>}
            {checkboxes?.map((checkbox, i) => {
                return (
                    <div className="option-container" key={'checkbox-' + checkbox.value}>
                        <input {...register(name, {required: i === 0 ? isRequired : false})}
                               id={checkbox.value}
                               value={checkbox.value || ''}
                               name={name}
                               type="checkbox"
                        />
                        <label htmlFor={checkbox.value}>{checkbox.label}</label>
                    </div>
                )
            })}
            {!!errors[name] && <p className='text-orange'>{invalidMessage}</p>}
        </div>
    );
};