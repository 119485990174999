import {useFormContext} from "react-hook-form";
import {RegionsAutoCompleteModel} from "../../../../services/models/forms/fields/RegionsAutoCompleteModel";

export const RegionsAutoComplete = (field: RegionsAutoCompleteModel) => {
    const {name, label, list, isRequired, invalidMessage} = field
    const {register, formState: {errors},} = useFormContext();

    return <div className="input-container">
        <label htmlFor={name}>{label}</label>
        <input className="text-input" list={list} id={name} {...register(name, {required: isRequired})}/>
        <datalist id="regions">
            <option value="1">1 - Ain</option>
            <option value="2">2 - Aisne</option>
            <option value="3">3 - Allier</option>
            <option value="4">4 - Alpes-de-Haute-Provence</option>
            <option value="5">5 - Hautes-Alpes</option>
            <option value="6">6 - Alpes-Maritimes</option>
            <option value="7">7 - Ardèche</option>
            <option value="8">8 - Ardennes</option>
            <option value="9">9 - Ariège</option>
            <option value="10">10 - Aube</option>
            <option value="11">11 - Aude</option>
            <option value="12">12 - Aveyron</option>
            <option value="13">13 - Bouches-du-Rhône</option>
            <option value="14">14 - Calvados</option>
            <option value="15">15 - Cantal</option>
            <option value="16">16 - Charente</option>
            <option value="17">17 - Charente-Maritime</option>
            <option value="18">18 - Cher</option>
            <option value="19">19 - Corrèze</option>
            <option value="21">21 - Côte-d'Or</option>
            <option value="22">22 - Côtes-d'Armor</option>
            <option value="23">23 - Creuse</option>
            <option value="24">24 - Dordogne</option>
            <option value="25">25 - Doubs</option>
            <option value="26">26 - Drôme</option>
            <option value="27">27 - Eure</option>
            <option value="28">28 - Eure-et-Loir</option>
            <option value="29">29 - Finistère</option>
            <option value="2A">2A - Corse-du-Sud</option>
            <option value="2B">2B - Haute-Corse</option>
            <option value="30">30 - Gard</option>
            <option value="31">31 - Haute-Garonne</option>
            <option value="32">32 - Gers</option>
            <option value="33">33 - Gironde</option>
            <option value="34">34 - Hérault</option>
            <option value="35">35 - Ille-et-Vilaine</option>
            <option value="36">36 - Indre</option>
            <option value="37">37 - Indre-et-Loire</option>
            <option value="38">38 - Isère</option>
            <option value="39">39 - Jura</option>
            <option value="40">40 - Landes</option>
            <option value="41">41 - Loir-et-Cher</option>
            <option value="42">42 - Loire</option>
            <option value="43">43 - Haute-Loire</option>
            <option value="44">44 - Loire-Atlantique</option>
            <option value="45">45 - Loiret</option>
            <option value="46">46 - Lot</option>
            <option value="47">47 - Lot-et-Garonne</option>
            <option value="48">48 - Lozère</option>
            <option value="49">49 - Maine-et-Loire</option>
            <option value="50">50 - Manche</option>
            <option value="51">51 - Marne</option>
            <option value="52">52 - Haute-Marne</option>
            <option value="53">53 - Mayenne</option>
            <option value="54">54 - Meurthe-et-Moselle</option>
            <option value="55">55 - Meuse</option>
            <option value="56">56 - Morbihan</option>
            <option value="57">57 - Moselle</option>
            <option value="58">58 - Nièvre</option>
            <option value="59">59 - Nord</option>
            <option value="60">60 - Oise</option>
            <option value="61">61 - Orne</option>
            <option value="62">62 - Pas-de-Calais</option>
            <option value="63">63 - Puy-de-Dôme</option>
            <option value="64">64 - Pyrénées-Atlantiques</option>
            <option value="65">65 - Hautes-Pyrénées</option>
            <option value="66">66 - Pyrénées-Orientales</option>
            <option value="67">67 - Bas-Rhin</option>
            <option value="68">68 - Haut-Rhin</option>
            <option value="69">69 - Rhône</option>
            <option value="70">70 - Haute-Saône</option>
            <option value="71">71 - Saône-et-Loire</option>
            <option value="72">72 - Sarthe</option>
            <option value="73">73 - Savoie</option>
            <option value="74">74 - Haute-Savoie</option>
            <option value="75">75 - Paris</option>
            <option value="76">76 - Seine-Maritime</option>
            <option value="77">77 - Seine-et-Marne</option>
            <option value="78">78 - Yvelines</option>
            <option value="79">79 - Deux-Sèvres</option>
            <option value="80">80 - Somme</option>
            <option value="81">81 - Tarn</option>
            <option value="82">82 - Tarn-et-Garonne</option>
            <option value="83">83 - Var</option>
            <option value="84">84 - Vaucluse</option>
            <option value="85">85 - Vendée</option>
            <option value="86">86 - Vienne</option>
            <option value="87">87 - Haute-Vienne</option>
            <option value="88">88 - Vosges</option>
            <option value="89">89 - Yonne</option>
            <option value="90">90 - Territoire de Belfort</option>
            <option value="91">91 - Essonne</option>
            <option value="92">92 - Hauts-de-Seine</option>
            <option value="93">93 - Seine-Saint-Denis</option>
            <option value="94">94 - Val-de-Marne</option>
            <option value="95">95 - Val-d'Oise</option>
            <option value="971">971 - Guadeloupe</option>
            <option value="972">972 - Martinique</option>
            <option value="973">973 - Guyane</option>
            <option value="974">974 - La Réunion</option>
            <option value="976">976 - Mayotte</option>
        </datalist>

        <datalist id="countries">
            <option value="Afghanistan">Afghanistan</option>
            <option value="Åland Islands">Åland Islands</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
            <option value="Brunei Darussalam">Brunei Darussalam</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote D'ivoire">Cote D'ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Territories">French Southern Territories</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guernsey">Guernsey</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-bissau">Guinea-bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Isle of Man">Isle of Man</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jersey">Jersey</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
            <option value="Korea, Republic of">Korea, Republic of</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macao">Macao</option>
            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of
            </option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
            <option value="Moldova, Republic of">Moldova, Republic of</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Antilles">Netherlands Antilles</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn">Pitcairn</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russian Federation">Russian Federation</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands
            </option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
            <option value="Thailand">Thailand</option>
            <option value="Timor-leste">Timor-leste</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Viet Nam">Viet Nam</option>
            <option value="Virgin Islands, British">Virgin Islands, British</option>
            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Western Sahara">Western Sahara</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
        </datalist>
        {!!errors[name] && <p className='error-message'>{invalidMessage}</p>}
    </div>
}