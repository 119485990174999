import {GridItemModel} from "../../../services/models/pages/items/GridItemModel";
import {H5} from "../headings/H5";
import {Link} from "react-router-dom";
import React from "react";
import {BaseImage} from "../figures/BaseImage";

type Types = {
    gridItem: GridItemModel
    linkBase: string
}

const GridItemWithLinkComponent = ({gridItem, linkBase}: Types) => {
    const {name, snippet, slug, cover} = gridItem
    return <li className="_image-container _roundedWithShadow">
        <Link to={linkBase + '/' + slug}>
            <BaseImage
                src={cover?.medium || cover?.basic}
                alt={name}/>
            <div
                className="_hidden-details">
                <H5>{name}</H5>
                <p className="text-sm">{snippet}</p>
            </div>
        </Link>
    </li>
}

export const GridItemWithLink = React.memo(GridItemWithLinkComponent)