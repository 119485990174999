import {FieldModel} from "./FieldModel";
import {RegionsAutoCompleteDataType} from "../../../api/types/FormDataTypes";

export class RegionsAutoCompleteModel extends FieldModel {
    list: string

    constructor(data: RegionsAutoCompleteDataType) {
        super(data)
        this.list = data.list
    }
}