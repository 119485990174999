import {IndexProjectModel} from "../../../services/models/pages/items/IndexProjectModel";
import {ProjectImageFrame} from "../figures/ProjectImageFrame";
import {Tag} from "../tags/Tag";
import {TechnologyTag} from "../tags/TechnologyTag";
import ReactMarkdown from "react-markdown";
import {RoundedLinkButton} from "../buttons/RoundedLinkButton";
import {H3} from "../headings/H3";
import {useGlobalContext} from "../../../services/providers/GlobalProvider";

type Types = {
    project: IndexProjectModel
}
export const ProjectItem = ({project}: Types) => {
    const {pairs} = useGlobalContext()
    const {
        name,
        description,
        collaborators,
        currentReleaseDate,
        startDate,
        desktopScreencap,
        mobileScreencap,
        slug,
        technologies,
    } = project

    return <article className="group" id={slug}>
        <ProjectImageFrame desktopImage={desktopScreencap} mobileImage={mobileScreencap} projectName={name}/>
        <div
            className="item-details">
            <div className="flex gap-4 items-center justify-between">
                <H3>{project.name}</H3>
                <RoundedLinkButton text={pairs?.learnMore || ''} page={project.slug} link={''}
                                   color={'hidden md:block bg-orange'}/>
            </div>
            {!!startDate && <div>Dates
				: {startDate?.toLocaleDateString()}{currentReleaseDate && (' ⇾ ' + currentReleaseDate.toLocaleDateString())} </div>}
            <div className="flex justify-around gap-4 flex-wrap">
                {collaborators?.length ? collaborators?.map((collaborator, i) => {
                    return <Tag key={collaborator} text={collaborator} index={i}/>
                }) : <Tag text={pairs?.personalProject || ''} index={0}/>}
            </div>
            <div className="flex gap-4 justify-around flex-wrap">
                {technologies?.length ? technologies.map(techno => {
                    return <TechnologyTag key={'tag-' + techno.name} technology={techno}/>
                }) : <p className="text-blue text-md text-center">{pairs?.noTechnology}</p>}
            </div>
            <ReactMarkdown className="text-justify indent-4 text-md">{description}</ReactMarkdown>
            <RoundedLinkButton text={pairs?.learnMore || ''} page={project.slug} link={''}
                               color={'md:hidden block bg-orange'}/>
        </div>
    </article>
}